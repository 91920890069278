import React, { useState } from 'react';
import { checkInChild, checkOutChild, getChildren } from '../services/api';
import { toast } from 'react-toastify';

const ParentCheckInOut = () => {
  const [code, setCode] = useState('');
  const [children, setChildren] = useState([]);
  const [error, setError] = useState('');

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getChildren(); // Replace with API to fetch children for the parent with the code
      setChildren(response.data);
      setError('');
    } catch (err) {
      setError('Invalid code or error fetching children');
    }
  };

  const handleCheckIn = async (childId) => {
    try {
      await checkInChild(childId);
      toast.success('Child checked in successfully!');
    } catch (err) {
      toast.error('Error checking in child');
    }
  };

  const handleCheckOut = async (childId) => {
    try {
      await checkOutChild(childId);
      toast.success('Child checked out successfully!');
    } catch (err) {
      toast.error('Error checking out child');
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Parent Check-In/Out</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleCodeSubmit} className="mb-4">
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter 6-digit code"
          className="border p-2 w-full rounded"
        />
        <button className="mt-2 bg-blue-500 text-white py-2 px-4 rounded w-full">Submit</button>
      </form>

      <div>
        {children.length > 0 && (
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2">Child</th>
                <th className="py-2">Check-In/Out</th>
              </tr>
            </thead>
            <tbody>
              {children.map((child) => (
                <tr key={child.id}>
                  <td className="py-2">{child.first_name} {child.last_name}</td>
                  <td className="py-2">
                    <button onClick={() => handleCheckIn(child.id)} className="bg-green-500 text-white px-4 py-2 rounded mr-2">Check In</button>
                    <button onClick={() => handleCheckOut(child.id)} className="bg-red-500 text-white px-4 py-2 rounded">Check Out</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ParentCheckInOut;
