import React from 'react';
import { AlertCircle } from 'lucide-react';

const CommunicationDetail = ({ communication = null }) => {
    // Early return if no communication data
    if (!communication) {
        return (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                <div className="flex items-center">
                    <AlertCircle className="h-5 w-5 text-red-400" />
                    <p className="ml-3 text-red-700">
                        Communication details not found
                    </p>
                </div>
            </div>
        );
    }

    // Only destructure after confirming communication exists
    const {
        title = 'Untitled Communication',
        sender_name = 'Unknown Sender',
        message = '',
        attachment_url = '',
        created_at = null
    } = communication;

    const formatDate = (dateString) => {
        if (!dateString) return 'Date unavailable';

        try {
            return new Date(dateString).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } catch (error) {
            return 'Date unavailable';
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-6" role="article">
            <h2 className="text-2xl font-bold mb-4" id="communication-title">
                {title}
            </h2>
            <div className="mb-4">
                <span className="text-gray-600">From: </span>
                <span className="font-semibold">{sender_name}</span>
            </div>
            <div
                className="prose max-w-none mb-6"
                aria-label="Message content"
            >
                {message}
            </div>
            {attachment_url && (
                <div className="mb-4">
                    <a
                        href={attachment_url}
                        className="inline-flex items-center gap-2 text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Download attachment"
                    >
                        Download Attachment
                    </a>
                </div>
            )}
            <div
                className="text-sm text-gray-500"
                aria-label="Sent date"
            >
                Sent on: {formatDate(created_at)}
            </div>
        </div>
    );
};

export default CommunicationDetail;