import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FileDown, Eye, Search, X, ChevronLeft } from 'lucide-react';
import api from '../../services/api';


const LessonDetailPanel = ({ lesson, onClose }) => {

    const handleDownload = async (fileUrl, fileName) => {
        try {
            const response = await fetch(fileUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A';
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    if (!lesson) return null;

    return (
        <div className="fixed inset-y-0 right-0 w-full md:w-2/3 lg:w-1/2 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 overflow-y-auto">
            <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex items-center justify-between">
                <button
                    onClick={onClose}
                    className="inline-flex items-center text-gray-600 hover:text-gray-900"
                >
                    <ChevronLeft className="h-5 w-5 mr-1" />
                    Back to Lessons
                </button>
            </div>

            <div className="p-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">{lesson.title}</h1>

                {lesson.event && (
                    <div className="mb-4">
                        <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded">
                            {lesson.event_name}
                        </span>
                    </div>
                )}

                <div className="mb-6">
                    <h2 className="text-lg font-semibold text-gray-700 mb-2">Description</h2>
                    <p className="text-gray-600 whitespace-pre-wrap">{lesson.description}</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg font-semibold text-gray-700 mb-2">Content</h2>
                    <div className="bg-gray-50 rounded-lg p-4">
                        <div className="prose max-w-none">
                            {lesson.content}
                        </div>
                    </div>
                </div>

                {lesson.attachments && lesson.attachments.length > 0 && (
                    <div className="mb-6">
                        <h2 className="text-lg font-semibold text-gray-700 mb-2">
                            Attachments ({lesson.attachments.length})
                        </h2>
                        <div className="space-y-2">
                            {lesson.attachments.map((file) => (
                                <div
                                    key={file.id}
                                    className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
                                >
                                    <span className="truncate flex-1">{file.file_name}</span>
                                    <div className="flex gap-2 ml-2">
                                        <button
                                            onClick={() => window.open(file.file_url, '_blank')}
                                            className="p-2 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-50"
                                            title="View"
                                        >
                                            <Eye className="h-5 w-5" />
                                        </button>
                                        <button
                                            onClick={() => handleDownload(file.file_url, file.file_name)}
                                            className="p-2 text-green-600 hover:text-green-800 rounded-full hover:bg-green-50"
                                            title="Download"
                                        >
                                            <FileDown className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="mt-6 text-sm text-gray-500">
                    <p>Created by: {lesson.created_by || 'Unknown'}</p>
                    <p>Last updated: {formatDate(lesson.updated_at)}</p>
                </div>
            </div>
        </div>
    );
};

const StaffLeaderLessons = () => {

    const [lessons, setLessons] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [showingAttachments, setShowingAttachments] = useState({});
    const [selectedLesson, setSelectedLesson] = useState(null);
    const { user, refreshUser } = useAuth();

    useEffect(() => {
        setCurrentUser(user);
        fetchLessons();
    }, []);

    useEffect(() => {
        // Add/remove class to prevent body scrolling when detail panel is open
        if (selectedLesson) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [selectedLesson]);

    const fetchLessons = async () => {
        try {
            const response = await api.get('lessons/');
            console.log("Lessons: ", response);
            setLessons(response.data);

        } catch (error) {
            console.error('Error fetching lessons:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (fileUrl, fileName) => {
        try {
            const response = await fetch(fileUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A';
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const toggleAttachments = (lessonId) => {
        setShowingAttachments(prev => ({
            ...prev,
            [lessonId]: !prev[lessonId]
        }));
    };

    const filteredLessons = lessons.filter(lesson =>
        lesson.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lesson.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    return (
        <>
            <div className="container mx-auto p-4">
                <h1 className="text-4xl font-bold text-blue-600 mb-8">Lessons</h1>

                {/* Search Bar */}
                <div className="mb-6 relative">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search lessons..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-3 pl-10 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <Search className="absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
                        {searchTerm && (
                            <button
                                onClick={() => setSearchTerm('')}
                                className="absolute right-3 top-3.5 text-gray-400 hover:text-gray-600"
                            >
                                <X className="h-5 w-5" />
                            </button>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredLessons.map((lesson) => (
                        <div
                            key={lesson.id}
                            className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow duration-300"
                        >
                            <div className="p-6">
                                <div className="flex justify-between items-start mb-4">
                                    <button
                                        onClick={() => setSelectedLesson(lesson)}
                                        className="text-2xl font-bold text-gray-800 hover:text-blue-600 text-left"
                                    >
                                        {lesson.title}
                                    </button>
                                </div>

                                <div className="text-gray-600 mb-4">
                                    <p className="mb-2">{lesson.description}</p>
                                    <p className="text-sm">Date: {formatDate(lesson.scheduled_date)}</p>
                                    {lesson.event_name && (
                                        <p className="text-sm mt-1">Event: {lesson.event_name}</p>
                                    )}
                                </div>

                                {lesson.attachments && lesson.attachments.length > 0 && (
                                    <div className="mt-4">
                                        <button
                                            onClick={() => toggleAttachments(lesson.id)}
                                            className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                                        >
                                            {showingAttachments[lesson.id] ? 'Hide' : 'Show'} Attachments ({lesson.attachments.length})
                                        </button>

                                        {showingAttachments[lesson.id] && (
                                            <div className="mt-2 space-y-2">
                                                {lesson.attachments.map((file) => (
                                                    <div
                                                        key={file.id}
                                                        className="flex items-center justify-between bg-gray-50 p-2 rounded-lg text-sm"
                                                    >
                                                        <span className="truncate flex-1">{file.file_name}</span>
                                                        <div className="flex gap-2 ml-2">
                                                            <button
                                                                onClick={() => window.open(file.file_url, '_blank')}
                                                                className="p-1 text-blue-600 hover:text-blue-800"
                                                                title="View"
                                                            >
                                                                <Eye className="h-4 w-4" />
                                                            </button>
                                                            <button
                                                                onClick={() => handleDownload(file.file_url, file.file_name)}
                                                                className="p-1 text-green-600 hover:text-green-800"
                                                                title="Download"
                                                            >
                                                                <FileDown className="h-4 w-4" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {filteredLessons.length === 0 && (
                    <div className="text-center text-gray-500 mt-8 p-6 bg-gray-50 rounded-lg">
                        <p className="text-lg">
                            {searchTerm ? 'No lessons match your search' : 'No lessons available at the moment.'}
                        </p>
                    </div>
                )}
            </div>
            {/* Backdrop */}
            {
                selectedLesson && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-25 z-40"
                        onClick={() => setSelectedLesson(null)}
                    />
                )
            }

            {/* Detail Panel */}
            {
                selectedLesson && (
                    <LessonDetailPanel
                        lesson={selectedLesson}
                        onClose={() => setSelectedLesson(null)}
                    />
                )
            }
        </>
    );
};

export default StaffLeaderLessons;