import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Plus, Edit2, Trash2, X, Calendar, Clock, Users, MapPin } from 'lucide-react';
import Header from '../components/Header';
import { format } from 'date-fns';
import api from '../services/api';

const EventManagement = () => {
    const [events, setEvents] = useState([]);
    const [campuses, setCampuses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
    const [editingEvent, setEditingEvent] = useState(null);
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [newEvent, setNewEvent] = useState({
        name: '',
        description: '',
        campus: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        frequency: 'ONCE',
        custom_address: '',
        capacity: 0,
        minimum_age: '',
        maximum_age: '',
        status: 'DRAFT',
        requires_registration: false,
        registration_deadline: '',
        is_active: true
    });

    useEffect(() => {
        fetchEvents();
        fetchCampuses();
    }, [selectedCampus, selectedStatus]);

    const fetchEvents = async () => {
        setIsLoading(true);
        try {
            let url = 'events/';  // Add leading slash and ensure trailing slash
            const params = new URLSearchParams();
            if (selectedCampus) params.append('campus', selectedCampus);
            if (selectedStatus) params.append('status', selectedStatus);
            if (params.toString()) url += `?${params.toString()}`;
            const response = await api.get(url);
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching events:', error.response || error);
            toast.error(error.response?.data?.detail || error.message || 'Failed to fetch events');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCampuses = async () => {
        try {
            const response = await api.get('campuses/');
            setCampuses(response.data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'capacity':
            case 'minimum_age':
            case 'maximum_age':
                return value === '' || (!isNaN(value) && parseInt(value) >= 0);
            case 'registration_deadline':
            case 'start_date':
            case 'end_date':
                return value === '' || /^\d{4}-\d{2}-\d{2}$/.test(value);
            case 'start_time':
            case 'end_time':
            case 'registration_deadline_time':
                return value === '' || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(value);
            default:
                return true;
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewEvent(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const eventData = {
                ...newEvent,
                // Convert string numbers to integers, use 0 if empty
                capacity: parseInt(newEvent.capacity) || 0,
                minimum_age: parseInt(newEvent.minimum_age) || 0,
                maximum_age: parseInt(newEvent.maximum_age) || 0,
                // Format datetime fields
                start_datetime: `${newEvent.start_date}T${newEvent.start_time}:00`,
                end_datetime: `${newEvent.end_date}T${newEvent.end_time}:00`,
                registration_deadline: `${newEvent.registration_deadline}T23:59:59`
            };

            delete eventData.start_date;
            delete eventData.start_time;
            delete eventData.end_date;
            delete eventData.end_time;

            console.log('Submitting:', eventData);
            console.log('Submitting event data:', newEvent);
            const response = await api.post('events/', newEvent);
            // If the request was successful
            const data = await response.data;
            console.log('Success response:', data);

            /*
            setNewEvent({
                name: '',
                description: '',
                campus: '',
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                frequency: 'ONCE',
                custom_address: '',
                capacity: '',
                minimum_age: '',
                maximum_age: '',
                status: 'DRAFT',
                requires_registration: false,
                registration_deadline: '',
                is_active: true
            });
            */
            toast.success('Event created successfully!');
            setIsRightPanelOpen(false);
            resetForm();
            fetchEvents();
        } catch (error) {
            // Log the full error response
            console.error('Error details:', error.response?.data);

            // Show a more specific error message
            const errorMessage = error.response?.data?.detail ||
                error.response?.data?.message ||
                error.message ||
                'Failed to create event';

            toast.error(error.message);
        }
    };

    const handleUpdate = async () => {
        try {
            const response = await api.put(`events/${editingEvent.id}/`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newEvent),
            });
            if (!response.ok) throw new Error('Failed to update event');

            toast.success('Event updated successfully!');
            setIsRightPanelOpen(false);
            resetForm();
            fetchEvents();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this event?')) return;

        try {
            const response = await api.delete(`events/${id}/`, {
            });
            if (!response.ok) throw new Error('Failed to delete event');

            toast.success('Event deleted successfully!');
            fetchEvents();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const resetForm = () => {
        setNewEvent({
            name: '',
            description: '',
            campus: '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            frequency: 'ONCE',
            custom_address: '',
            capacity: '',
            minimum_age: '',
            maximum_age: '',
            status: 'DRAFT',
            requires_registration: false,
            registration_deadline: '',
            is_active: true
        });
        setEditingEvent(null);
    };

    return (
        <>

            <div className="container mx-auto px-4 py-8">
                <ToastContainer />

                {/* Header Section */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Glory Event Management</h2>
                    <button
                        onClick={() => {
                            resetForm();
                            setIsRightPanelOpen(true);
                        }}
                        className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        <Plus size={20} />
                        Create Event
                    </button>
                </div>

                {/* Filters */}
                <div className="mb-6 flex flex-col sm:flex-row gap-4">
                    <select
                        value={selectedCampus}
                        onChange={(e) => setSelectedCampus(e.target.value)}
                        className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                        <option value="">All Campuses</option>
                        {campuses.map(campus => (
                            <option key={campus.id} value={campus.id}>{campus.name}</option>
                        ))}
                    </select>

                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                        <option value="">All Statuses</option>
                        <option value="DRAFT">Draft</option>
                        <option value="PUBLISHED">Published</option>
                        <option value="CANCELLED">Cancelled</option>
                        <option value="COMPLETED">Completed</option>
                    </select>
                </div>

                {/* Events Grid */}
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {events.map(event => (
                            <div key={event.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                                <div className="p-6">
                                    <div className="flex justify-between items-start mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800">{event.name}</h3>
                                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${event.status === 'PUBLISHED' ? 'bg-green-100 text-green-800' :
                                            event.status === 'DRAFT' ? 'bg-yellow-100 text-yellow-800' :
                                                event.status === 'CANCELLED' ? 'bg-red-100 text-red-800' :
                                                    'bg-gray-100 text-gray-800'
                                            }`}>
                                            {event.status}
                                        </span>
                                    </div>

                                    <div className="space-y-3 text-sm text-gray-600">
                                        <div className="flex items-center gap-2">
                                            <MapPin size={16} />
                                            <span>{event.custom_address || event.campus_name}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Calendar size={16} />
                                            <span>{format(new Date(event.start_date), 'MMM d, yyyy')}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Clock size={16} />
                                            <span>{format(new Date(`2000-01-01T${event.start_time}`), 'h:mm a')} - {format(new Date(`2000-01-01T${event.end_time}`), 'h:mm a')}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Users size={16} />
                                            <span>{event.staff_count} Staff · {event.registered_count} Registered</span>
                                        </div>
                                    </div>

                                    <div className="mt-4 pt-4 border-t border-gray-100 flex justify-end gap-2">
                                        <button
                                            onClick={() => {
                                                setEditingEvent(event);
                                                setNewEvent(event);
                                                setIsRightPanelOpen(true);
                                            }}
                                            className="p-1 text-yellow-600 hover:text-yellow-700"
                                        >
                                            <Edit2 size={16} />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(event.id)}
                                            className="p-1 text-red-600 hover:text-red-700"
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {events.length === 0 && (
                            <div className="col-span-full text-center py-12 text-gray-500">
                                No events found. Create one by clicking the "Create Event" button.
                            </div>
                        )}
                    </div>
                )}

                {/* Right Panel for Add/Edit Event */}
                <div className={`fixed inset-y-0 right-0 w-[600px] bg-white shadow-lg transform ${isRightPanelOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
                    <div className="h-full flex flex-col p-6 overflow-y-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-xl font-bold text-gray-800">
                                {editingEvent ? 'Edit Event' : 'Create New Event'}
                            </h3>
                            <button
                                onClick={() => {
                                    setIsRightPanelOpen(false);
                                    resetForm();
                                }}
                                className="p-2 hover:bg-gray-100 rounded-full"
                            >
                                <X size={20} />
                            </button>
                        </div>

                        <form onSubmit={editingEvent ? handleUpdate : handleSubmit} className="flex-1 flex flex-col gap-4">
                            <div className="grid grid-cols-1 gap-4">
                                {/* Basic Information */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Basic Information</h4>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Event Name"
                                        value={newEvent.name}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        required
                                    />
                                    <textarea
                                        name="description"
                                        placeholder="Event Description"
                                        value={newEvent.description}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent h-32"
                                    />
                                    <select
                                        name="campus"
                                        value={newEvent.campus}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        required
                                    >
                                        <option value="">Select Campus</option>
                                        {campuses.map(campus => (
                                            <option key={campus.id} value={campus.id}>{campus.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* Date and Time */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Date and Time</h4>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Start Date</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={newEvent.start_date}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">End Date</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                value={newEvent.end_date}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Start Time</label>
                                            <input
                                                type="time"
                                                name="start_time"
                                                value={newEvent.start_time}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">End Time</label>
                                            <input
                                                type="time"
                                                name="end_time"
                                                value={newEvent.end_time}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <select
                                        name="frequency"
                                        value={newEvent.frequency}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        required
                                    >
                                        <option value="ONCE">One-time Event</option>
                                        <option value="DAILY">Daily</option>
                                        <option value="WEEKLY">Weekly</option>
                                        <option value="MONTHLY">Monthly</option>
                                    </select>
                                </div>

                                {/* Location */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Location</h4>
                                    <textarea
                                        name="custom_address"
                                        placeholder="Custom Address (optional)"
                                        value={newEvent.custom_address}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    />
                                </div>

                                {/* Capacity and Age Restrictions */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Capacity and Age Restrictions</h4>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Capacity</label>
                                            <input
                                                type="number"
                                                name="capacity"
                                                placeholder="Optional"
                                                value={newEvent.capacity}
                                                onChange={handleInputChange}
                                                min="0"
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Min Age</label>
                                            <input
                                                type="number"
                                                name="minimum_age"
                                                placeholder="Optional"
                                                value={newEvent.minimum_age}
                                                onChange={handleInputChange}
                                                min="0"
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Max Age</label>
                                            <input
                                                type="number"
                                                name="maximum_age"
                                                placeholder="Optional"
                                                value={newEvent.maximum_age}
                                                onChange={handleInputChange}
                                                min="0"
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Registration Settings */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Registration Settings</h4>
                                    <div className="flex items-center gap-2">
                                        <input
                                            type="checkbox"
                                            id="requires_registration"
                                            name="requires_registration"
                                            checked={newEvent.requires_registration}
                                            onChange={handleInputChange}
                                            className="rounded border-gray-300"
                                        />
                                        <label htmlFor="requires_registration">Requires Registration</label>
                                    </div>
                                    {newEvent.requires_registration && (
                                        <div>
                                            <label className="block text-sm text-gray-600 mb-1">Registration Deadline</label>
                                            <input
                                                type="datetime-local"
                                                name="registration_deadline"
                                                value={newEvent.registration_deadline}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Status */}
                                <div className="space-y-4">
                                    <h4 className="font-medium text-gray-700">Status</h4>
                                    <select
                                        name="status"
                                        value={newEvent.status}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        required
                                    >
                                        <option value="DRAFT">Draft</option>
                                        <option value="PUBLISHED">Published</option>
                                        <option value="CANCELLED">Cancelled</option>
                                        <option value="COMPLETED">Completed</option>
                                    </select>
                                </div>
                            </div>

                            <div className="sticky bottom-0 bg-white pt-4 border-t mt-auto">
                                <button
                                    type="submit"
                                    className="w-full bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition-colors"
                                >
                                    {editingEvent ? 'Update Event' : 'Create Event'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventManagement;