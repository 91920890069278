import React, { useState, useEffect } from 'react';
import { createUser, getUsers, updateUser, deleteUser } from '../services/api';
import api from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import { Plus, X, Search, Edit2, Trash2 } from 'lucide-react';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', first_name: '', last_name: '', role: 'PARENT' });
  const [editingUser, setEditingUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [campuses, setCampuses] = useState([]);
  const [editingParentProfile, setEditingParentProfile] = useState(null);
  const [parentProfile, setParentProfile] = useState({
    primary_phone: '',
    secondary_phone: '',
    campus: '',
    //hp: '',
    spouse: null
  });

  const paginateData = (items) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return items.slice(indexOfFirstItem, indexOfLastItem);
  };


  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchCampuses = async () => {
      try {
        const response = await api.get('campuses/');
        setCampuses(response.data);
      } catch (error) {
        toast.error('Failed to fetch campuses');
      }
    };
    fetchCampuses();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      if (error.response) {
        toast.error('error.response.data');
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      toast.error(setError('Failed to fetch users. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsCreating(true);
    try {
      // First create the user
      const userResponse = await createUser(newUser);

      if (userResponse.data) {
        if (newUser.role === 'PARENT') {
          try {
            await api.post('parent-profiles/', {
              user: userResponse.data.id,
              ...parentProfile
            });
          } catch (profileError) {
            console.error('Error creating parent profile:', profileError);
            throw new Error('Failed to create parent profile');
          }
        } else {
          try {
            // For non-parent users, update their campus
            await api.patch(`users/${userResponse.data.id}/`, {
              campus: newUser.campus
            });
          } catch (campusError) {
            console.error('Error updating campus:', campusError);
            throw new Error('Failed to update campus');
          }
        }

        // Successfully created user and profile
        toast.success('User created successfully!');
        setNewUser({ username: '', email: '', password: '', first_name: '', last_name: '', role: 'PARENT' });
        setParentProfile({
          primary_phone: '',
          secondary_phone: '',
          campus: '',
          //hp: '',
          spouse: null
        });
        fetchUsers();
        setIsRightPanelOpen(false);
      }
    } catch (error) {
      console.error('Create user error:', error);
      const errorMessage = error.response?.data?.detail || error.message || 'Failed to create user';
      toast.error(errorMessage);
      setError(errorMessage);
    } finally {
      setIsCreating(false);
    }
  };


  const handleEdit = async (user) => {
    try {
      const userToEdit = { ...user, password: '' };
      setEditingUser(userToEdit);

      if (user.role === 'PARENT') {
        try {
          const response = await api.get(`parent-profiles/?user=${user.id}`);
          if (response.data && response.data.length > 0) {
            setEditingParentProfile(response.data[0]);
          } else {
            setEditingParentProfile({
              primary_phone: '',
              secondary_phone: '',
              campus: '',
              spouse: null
            });
          }
        } catch (error) {
          console.error('Error fetching parent profile:', error);
          toast.error('Failed to fetch parent profile');
        }
      }
    } catch (error) {
      console.error('Error setting up edit:', error);
      toast.error('Failed to prepare user edit');
    }
  };

  const handleUpdate = async () => {
    setError('');
    try {
      // First update the user
      const userUpdateData = {
        username: editingUser.username,
        email: editingUser.email,
        first_name: editingUser.first_name,
        last_name: editingUser.last_name,
        role: editingUser.role,
      };

      // Only include password if it was changed
      if (editingUser.password) {
        userUpdateData.password = editingUser.password;
      }

      // Update the user
      await updateUser(editingUser.id, userUpdateData);

      // If user is a parent, update their profile
      if (editingUser.role === 'PARENT' && editingParentProfile) {
        try {
          if (editingParentProfile.id) {
            // Update existing parent profile
            await api.patch(`parent-profiles/${editingParentProfile.id}/`, {
              primary_phone: editingParentProfile.primary_phone,
              secondary_phone: editingParentProfile.secondary_phone,
              campus: editingParentProfile.campus,
              spouse: editingParentProfile.spouse
            });
          } else {
            // Create new parent profile
            await api.post('parent-profiles/', {
              user: editingUser.id,
              ...editingParentProfile
            });
          }
        } catch (profileError) {
          console.error('Error updating parent profile:', profileError);
          throw new Error('Failed to update parent profile');
        }
      } else {
        // For non-parent users, update their campus if provided
        if (editingUser.campus) {
          await api.patch(`users/${editingUser.id}/`, {
            campus: editingUser.campus
          });
        }
      }

      toast.success('User updated successfully!');
      setEditingUser(null);
      setEditingParentProfile(null);
      fetchUsers();
    } catch (error) {
      console.error('Update error:', error);
      const errorMessage = error.response?.data?.detail || error.message || 'Failed to update user';
      toast.error(errorMessage);
      setError(errorMessage);
    }
  };

  const handleDelete = async (userId) => {
    setError('');
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        toast.success('User deleted successfully!');
        fetchUsers();
      } catch (error) {
        toast.error('Error deleting user. Contact your administrator');
        setError('Failed to delete user. Please try again.');
      }
    }
  };

  const filteredUsers = users.filter(user => {
    const searchMatch = (
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const roleMatch = filterRole ? user.role === filterRole : true;
    return searchMatch && roleMatch;
  });

  const paginatedUsers = paginateData(filteredUsers);

  const Pagination = () => {
    const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
    const pages = Array.from({ length: pageCount }, (_, i) => i + 1);

    if (pageCount <= 1) return null;

    return (
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">{((currentPage - 1) * itemsPerPage) + 1}</span>
              {' '}-{' '}
              <span className="font-medium">
                {Math.min(currentPage * itemsPerPage, filteredUsers.length)}
              </span>
              {' '}of{' '}
              <span className="font-medium">{filteredUsers.length}</span>
              {' '}results
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Previous</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                </svg>
              </button>
              {pages.map(page => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page
                    ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0'
                    }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
                disabled={currentPage === pageCount}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Next</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <ToastContainer />

        {/* Header Section */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">User Management</h2>
          <button
            onClick={() => setIsRightPanelOpen(true)}
            className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus size={20} />
            Add New User
          </button>
        </div>

        {/* Search and Filter Section */}
        <div className="mb-6 flex flex-col sm:flex-row gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search by name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <select
            value={filterRole}
            onChange={(e) => setFilterRole(e.target.value)}
            className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All Roles</option>
            <option value="PARENT">Parent</option>
            <option value="STAFF">Staff</option>
            <option value="LEADER">Leader</option>
          </select>
        </div>

        {/* Users Table */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {paginatedUsers.map(user => (
                    <tr key={user.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.first_name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.last_name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${user.role === 'PARENT' ? 'bg-green-100 text-green-800' :
                          user.role === 'STAFF' ? 'bg-blue-100 text-blue-800' :
                            'bg-purple-100 text-purple-800'
                          }`}>
                          {user.role}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleEdit(user)}
                            className="p-1 text-yellow-600 hover:text-yellow-700"
                          >
                            <Edit2 size={18} />
                          </button>
                          <button
                            onClick={() => handleDelete(user.id)}
                            className="p-1 text-red-600 hover:text-red-700"
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination />
          </div>

        )}


        {/* Right Panel for New User Form */}
        <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-lg transform ${isRightPanelOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
          <div className="h-full flex flex-col p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold text-gray-800">Add New User</h3>
              <button
                onClick={() => setIsRightPanelOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X size={20} />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="flex-1 flex flex-col gap-4">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={newUser.username}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={newUser.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={newUser.password}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                value={newUser.first_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={newUser.last_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <select
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              >
                <option value="PARENT">Parent</option>
                <option value="STAFF">Staff</option>
                <option value="LEADER">Leader</option>
              </select>
              {newUser.role !== 'PARENT' && (
                <select
                  name="campus"
                  value={newUser.campus}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                >
                  <option value="">Select Campus</option>
                  {campuses.map(campus => (
                    <option key={campus.id} value={campus.id}>{campus.name}</option>
                  ))}
                </select>
              )}
              {newUser.role === 'PARENT' && (
                <div className="space-y-4">
                  <input
                    type="tel"
                    name="primary_phone"
                    placeholder="Primary Phone (required)"
                    value={parentProfile.primary_phone}
                    onChange={(e) => setParentProfile({ ...parentProfile, primary_phone: e.target.value })}
                    className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                  <input
                    type="tel"
                    name="secondary_phone"
                    placeholder="Secondary Phone (optional)"
                    value={parentProfile.secondary_phone}
                    onChange={(e) => setParentProfile({ ...parentProfile, secondary_phone: e.target.value })}
                    className="w-full p-2 border border-gray-200 rounded-lg"
                  />
                  <select
                    name="campus"
                    value={parentProfile.campus}
                    onChange={(e) => setParentProfile({ ...parentProfile, campus: e.target.value })}
                    className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  >
                    <option value="">Select Campus</option>
                    {campuses.map(campus => (
                      <option key={campus.id} value={campus.id}>{campus.name}</option>
                    ))}
                  </select>
                  {/** 
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      name="hp"
                      placeholder="HP #"
                      value={newUser.hp}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                  */}
                </div>
              )}
              <button
                type="submit"
                className="mt-auto bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-400"
                disabled={isCreating}
                style={{ display: 'block', width: '100%' }} // Ensure button is always visible
              >
                {isCreating ? 'Creating...' : 'Create User'}
              </button>
            </form>
          </div>
        </div>

        {/* Edit User Modal */}
        {editingUser && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative mx-auto p-5 border w-full max-w-lg md:w-96 shadow-lg rounded-md bg-white mt-20">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Edit User</h3>
                <button
                  onClick={() => {
                    setEditingUser(null);
                    setEditingParentProfile(null);
                  }}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
                <form className="space-y-4">
                  <input
                    type="text"
                    name="username"
                    value={editingUser.username}
                    onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Username"
                  />
                  <input
                    type="email"
                    name="email"
                    value={editingUser.email}
                    onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Email"
                  />
                  <input
                    type="password"
                    name="password"
                    value={editingUser.password}
                    onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="New Password (leave blank to keep current)"
                  />
                  <input
                    type="text"
                    name="first_name"
                    value={editingUser.first_name}
                    onChange={(e) => setEditingUser({ ...editingUser, first_name: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    name="last_name"
                    value={editingUser.last_name}
                    onChange={(e) => setEditingUser({ ...editingUser, last_name: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Last Name"
                  />
                  <select
                    name="role"
                    value={editingUser.role}
                    onChange={(e) => setEditingUser({ ...editingUser, role: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="PARENT">Parent</option>
                    <option value="STAFF">Staff</option>
                    <option value="LEADER">Leader</option>
                  </select>

                  {editingUser.role !== 'PARENT' && (
                    <select
                      name="campus"
                      value={editingUser.campus}
                      onChange={(e) => setEditingUser({ ...editingUser, campus: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                      <option value="">Select Campus</option>
                      {campuses.map(campus => (
                        <option key={campus.id} value={campus.id}>{campus.name}</option>
                      ))}
                    </select>
                  )}

                  {editingUser.role === 'PARENT' && editingParentProfile && (
                    <div className="space-y-4">
                      <input
                        type="tel"
                        name="primary_phone"
                        placeholder="Primary Phone"
                        value={editingParentProfile.primary_phone}
                        onChange={(e) => setEditingParentProfile({
                          ...editingParentProfile,
                          primary_phone: e.target.value
                        })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <input
                        type="tel"
                        name="secondary_phone"
                        placeholder="Secondary Phone"
                        value={editingParentProfile.secondary_phone}
                        onChange={(e) => setEditingParentProfile({
                          ...editingParentProfile,
                          secondary_phone: e.target.value
                        })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <select
                        name="campus"
                        value={editingParentProfile.campus}
                        onChange={(e) => setEditingParentProfile({
                          ...editingParentProfile,
                          campus: e.target.value
                        })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      >
                        <option value="">Select Campus</option>
                        {campuses.map(campus => (
                          <option key={campus.id} value={campus.id}>{campus.name}</option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="flex justify-end space-x-2 pt-4">
                    <button
                      type="button"
                      onClick={() => {
                        setEditingUser(null);
                        setEditingParentProfile(null);
                      }}
                      className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleUpdate}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {/* Error Display */}
        {error && (
          <div className="fixed bottom-4 right-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-lg">
            {error}
          </div>
        )}
      </div>
    </>
  );
};

export default UserManagement;
