import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import UserManagement from './UserManagement';
import EventManagement from './EventManagement';
import CampusManagement from './CampusManagement';
import LessonManager from '../components/Lessons/LessonManager';


const Management = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.hash.replace('#', '') || 'users');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`/management#${tab}`);
    };

    const tabs = [
        { id: 'users', label: 'Users', icon: 'users' },
        { id: 'events', label: 'Events', icon: 'calendar' },
        { id: 'campuses', label: 'Campuses', icon: 'building' },
        { id: 'lessons', label: 'Lessons', icon: 'book' },
        { id: 'meetings', label: 'Meetings', icon: 'users-group' },
    ];

    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Management</h1>
                </div>

                {/* Tabs */}
                <div className="mb-6 border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                        {tabs.map(tab => (
                            <button
                                key={tab.id}
                                onClick={() => handleTabChange(tab.id)}
                                className={`
                  py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === tab.id
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
                            >
                                <i className={`fas fa-${tab.icon} mr-2`}></i>
                                {tab.label}
                            </button>
                        ))}
                    </nav>
                </div>

                {/* Content */}
                <div className="bg-white rounded-lg shadow">
                    {activeTab === 'users' && <UserManagement />}
                    {activeTab === 'events' && <EventManagement />}
                    {activeTab === 'campuses' && <CampusManagement />}
                    {activeTab === 'lessons' && <LessonManager />}
                    {/* 
                   {activeTab === 'lessons' && <LessonManagement />}
                    {activeTab === 'meetings' && <MeetingManagement />}
                    */}
                </div>
            </div>
        </>
    );
};

export default Management;