// src/components/Lessons/LessonManager.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Book, FileText, Share2, Calendar, Users,
    Plus, Edit2, Trash2, Upload
} from 'lucide-react';
import { toast } from 'react-toastify';
import LessonForm from './LessonForm';
import api from '../../services/api';

const LessonManager = () => {
    const [lessons, setLessons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [campuses, setCampuses] = useState([]);
    const [selectedCampus, setSelectedCampus] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [isLessonFormOpen, setIsLessonFormOpen] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null); // For edit mode

    const navigate = useNavigate();

    useEffect(() => {
        fetchLessons();
        fetchCampuses();
    }, [selectedCampus, statusFilter]);

    const fetchLessons = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('lessons/');
            setLessons(response.data || []);  // Set a default empty array if data is undefined
        } catch (error) {
            toast.error('Failed to load lessons');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCampuses = async () => {
        try {
            const response = await api.get('campuses/');
            setCampuses(response.data);
        } catch (error) {
            toast.error('Failed to load campuses');
        }
    };

    const handleDelete = async (lessonId) => {
        if (!window.confirm('Are you sure you want to delete this lesson?')) return;

        try {
            await api.delete(`lessons/${lessonId}/`);
            toast.success('Lesson deleted successfully');
            fetchLessons();
        } catch (error) {
            toast.error('Failed to delete lesson');
        }
    };

    const getStatusBadgeColor = (status) => {
        const colors = {
            DRAFT: 'bg-gray-100 text-gray-800',
            SCHEDULED: 'bg-blue-100 text-blue-800',
            COMPLETED: 'bg-green-100 text-green-800',
            CANCELLED: 'bg-red-100 text-red-800'
        };
        return colors[status] || 'bg-gray-100 text-gray-800';
    };

    const handleLessonSubmit = async (formData) => {
        try {
            const data = new FormData();

            // Append each form field to FormData (excluding files if not necessary)
            for (const key in formData) {
                data.append(key, formData[key]);
            }

            let response;
            if (selectedLesson) {
                response = await api.put(`lessons/${selectedLesson.id}/`, data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                toast.success('Lesson updated successfully');
            } else {
                response = await api.post('lessons/', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                // After creating, set the selectedLesson to enable file uploads
                setSelectedLesson(response.data);
                toast.success('Lesson created successfully');
            }

            // Don't close the form immediately after creation to allow file uploads
            if (selectedLesson) {
                setIsLessonFormOpen(false);
            }

            // Refresh the lessons list
            fetchLessons();
        } catch (error) {
            console.error('Error saving lesson:', error.response?.data);
            toast.error('Failed to save lesson');
        }
    };

    const editLesson = (lesson) => {
        setSelectedLesson(lesson);
        setIsLessonFormOpen(true);  // Open the form in edit mode
    };

    const openLessonForm = () => {
        setSelectedLesson(null);
        setIsLessonFormOpen(true);
    };

    const closeLessonForm = () => {
        setIsLessonFormOpen(false);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Lessons</h2>
                <button
                    onClick={openLessonForm}
                    className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                >
                    <Plus size={20} />
                    Create Lesson
                </button>
            </div>

            <div className="mb-6 flex flex-col sm:flex-row gap-4">
                <select
                    value={selectedCampus}
                    onChange={(e) => setSelectedCampus(e.target.value)}
                    className="px-4 py-2 border border-gray-200 rounded-lg"
                >
                    <option value="">All Campuses</option>
                    {campuses.map(campus => (
                        <option key={campus.id} value={campus.id}>{campus.name}</option>
                    ))}
                </select>

                <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="px-4 py-2 border border-gray-200 rounded-lg"
                >
                    <option value="">All Statuses</option>
                    <option value="DRAFT">Draft</option>
                    <option value="SCHEDULED">Scheduled</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="CANCELLED">Cancelled</option>
                </select>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {lessons.map(lesson => (
                        <div key={lesson.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                            <div className="p-6">
                                <div className="flex justify-between items-start mb-4">
                                    <h3 className="text-lg font-semibold text-gray-800">{lesson.title}</h3>
                                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusBadgeColor(lesson.status)}`}>
                                        {lesson.status}
                                    </span>
                                </div>
                                <div className="space-y-3 text-sm text-gray-600">
                                    <div className="flex items-center gap-2">
                                        <Calendar size={16} />
                                        <span>{lesson.scheduled_date || 'No date set'}</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Users size={16} />
                                        <span>{lesson.campus_name}</span>
                                    </div>
                                    {lesson.event_name && (
                                        <div className="flex items-center gap-2">
                                            <Calendar size={16} />
                                            <span>{lesson.event_name}</span>
                                        </div>
                                    )}
                                    <div className="flex items-center gap-2">
                                        <FileText size={16} />
                                        <span>{lesson.attachments ? lesson.attachments.length : 0} attachments</span>
                                    </div>
                                </div>
                                <div className="mt-4 pt-4 border-t border-gray-100 flex justify-between">
                                    <button
                                        onClick={() => handleDelete(lesson.id)}
                                        className="p-1 text-red-600 hover:text-red-700"
                                    >
                                        <Trash2 size={16} />
                                    </button>
                                    <button
                                        onClick={() => editLesson(lesson)}
                                        className="flex items-center gap-1 text-blue-600 hover:text-blue-700"
                                    >
                                        <Edit2 size={16} />
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {isLessonFormOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
                    <div className="bg-white w-2/3 h-full p-4 overflow-y-auto shadow-lg"> {/* Changed from w-1/3 to w-2/3 */}
                        <LessonForm
                            lesson={selectedLesson}
                            onSubmit={handleLessonSubmit}
                            isEditing={!!selectedLesson}
                        />
                        <button
                            onClick={closeLessonForm}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LessonManager;