import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Helper function to determine if user is a superuser based on role
    const determineSuperUser = (userData) => {
        // Consider user as superuser if either is_superuser is true OR role is 'SUPERUSER'
        return userData.is_superuser || userData.role === 'SUPERUSER';
    };

    // Helper function to process user data
    const processUserData = (userData) => {
        const isSuperUser = determineSuperUser(userData);
        return {
            ...userData,
            is_superuser: isSuperUser, // Override the backend value
            role: userData.role || (isSuperUser ? 'SUPERUSER' : 'PARENT'),
            // Add any additional user data processing here
        };
    };

    const getCurrentUser = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found');
            }

            const response = await api.get('current/');
            const processedUserData = processUserData(response.data);

            console.log('Processed user data:', processedUserData); // Debug log
            setUser(processedUserData);
            return processedUserData;
        } catch (error) {
            console.error('Error getting current user:', error);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setUser(null);
            throw error;
        }
    };

    const login = async (username, password) => {
        try {
            const response = await api.post('login/', { username, password });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);

                const processedUserData = processUserData(response.data.user);
                localStorage.setItem('user', JSON.stringify(processedUserData));
                setUser(processedUserData);

                console.log('Login processed user data:', processedUserData); // Debug log
                return response.data;
            }
            throw new Error('Login failed - no token received');
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await api.post('logout/');
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setUser(null);
            navigate('/login');
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const token = localStorage.getItem('token');
                const storedUser = localStorage.getItem('user');

                if (token && storedUser) {
                    // First set the stored user data
                    const parsedUser = JSON.parse(storedUser);
                    const processedStoredUser = processUserData(parsedUser);
                    setUser(processedStoredUser);

                    // Then refresh from server
                    await getCurrentUser();
                }
            } catch (error) {
                console.error('Auth initialization error:', error);
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, []);

    // Updated hasRole function to handle both role-based and superuser permissions
    const hasRole = (allowedRoles) => {
        if (!user) return false;

        // If user is a superuser (either by role or flag), they have access to everything
        if (user.is_superuser || user.role === 'SUPERUSER') return true;

        // Otherwise, check if their role is in the allowed roles
        return allowedRoles.includes(user.role);
    };

    const value = {
        user,
        loading,
        login,
        logout,
        getCurrentUser,
        hasRole,
        isAuthenticated: !!user
    };

    if (loading) {
        return <div className="min-h-screen flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};