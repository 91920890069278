// src/services/auth.js
import React, { useEffect, useState } from 'react';
import api from './api';


export const login = async (username, password) => {
  try {
    const response = await api.post('/login/', { username, password });
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const logout = async () => {
  try {
    await api.post('logout/');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  } catch (error) {
    console.error('Logout error:', error);
  }
};


export const getCurrentUser = async () => {
  try {
    const response = await api.get('current/');
    localStorage.setItem('currentUser', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};


/*
export const getStoredUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) {
    const user = JSON.parse(userStr);
    return user;
  }
  console.log('No user found in storage');
  return null;
};
*/



// Forgot username function
export const forgotUsername = async (email) => {
  try {
    const response = await api.post('forgot-username/', { email });  // Ensure the API route is correct
    return response.data;
  } catch (error) {
    console.error('Forgot username error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Forgot password function
export const forgotPassword = async (email) => {
  try {
    const response = await api.post('forgot-password/', { email });  // Ensure the API route is correct
    return response.data;
  } catch (error) {
    console.error('Forgot password error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


/*
export const forgotUsername = (email) => {
  return api.post('/forgot-username/', { email });
};

export const forgotPassword = (email) => {
  return api.post('/forgot-password/', { email });
};
*/