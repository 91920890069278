// src/components/events/EventRegistrationList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { MapPin, Calendar, Clock, Users } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';

const EventRegistrationList = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const { user, refreshUser } = useAuth();

    useEffect(() => {

        setCurrentUser(user);
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await api.get('/events/', {
                params: {
                    status: 'PUBLISHED'
                }
            });
            setEvents(response.data);
        } catch (err) {
            console.error('Error fetching events:', err);
            setError('Failed to fetch events');
            toast.error('Failed to fetch events');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container mx-auto px-4 py-8">
            <ToastContainer />

            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Available Events</h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {events.map(event => (
                    <div key={event.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-6">
                            <div className="flex justify-between items-start mb-4">
                                <h3 className="text-lg font-semibold text-gray-800">{event.name}</h3>
                                <span className="px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800">
                                    {event.registered_count} / {event.capacity || 'Unlimited'}
                                </span>
                            </div>

                            <p className="text-gray-600 mb-4">{event.description}</p>

                            <div className="space-y-3 text-sm text-gray-600">
                                <div className="flex items-center gap-2">
                                    <MapPin size={16} />
                                    <span>{event.custom_address || event.campus_name}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Calendar size={16} />
                                    <span>{format(new Date(event.start_date), 'MMM d, yyyy')}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Clock size={16} />
                                    <span>
                                        {format(new Date(`2000-01-01T${event.start_time}`), 'h:mm a')} -
                                        {format(new Date(`2000-01-01T${event.end_time}`), 'h:mm a')}
                                    </span>
                                </div>
                                {(event.minimum_age || event.maximum_age) && (
                                    <div className="flex items-center gap-2">
                                        <Users size={16} />
                                        <span>
                                            Age Requirements:
                                            {event.minimum_age && ` ${event.minimum_age}+ years`}
                                            {event.maximum_age && ` (up to ${event.maximum_age} years)`}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="mt-4 pt-4 border-t border-gray-100">
                                <Link
                                    to={`/event-registration/${event.id}`}
                                    className="w-full text-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors inline-block"
                                >
                                    View Registration Details
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}

                {events.length === 0 && (
                    <div className="col-span-full text-center py-12 text-gray-500">
                        No events available for registration at this time.
                    </div>
                )}
            </div>
        </div>
    );
};

export default EventRegistrationList;