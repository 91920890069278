import React, { useState } from 'react';
import { useCheckInContext } from '../contexts/CheckInContext';
import { getChildrenByCode } from '../services/api';  // API for fetching children by code
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ParentCheckInOut = () => {
  const { checkInChild, checkOutChild, fetchCheckedInChildren, checkedInChildren } = useCheckInContext();  // Pull context functions
  const [code, setCode] = useState('');
  const [children, setChildren] = useState([]);
  const [error, setError] = useState('');

  // Handle number pad input for 6-digit code
  const handleCodeInput = (digit) => {
    if (code.length < 6) {
      setCode((prevCode) => prevCode + digit);
    }
  };

  // Clear code input
  const handleClearCode = () => {
    setCode('');
    setChildren([]);
    setError('');
  };

  // Handle submit code and fetch children
  const handleSubmitCode = async () => {
    if (code.length === 6) {
      try {
        const response = await getChildrenByCode(code);  // Fetch children based on the parent's code
        if (response.data.length === 0) {
          setError('No children found for this code');
        } else {
          setChildren(response.data);
          setError('');
          toast.success('Children fetched successfully!');
        }
      } catch (err) {
        toast.error('Invalid code or error fetching children');
        setError('Invalid code');
      }
    } else {
      toast.error('Enter a 6-digit code');
    }
  };

  // Handle check-in for a child
  const handleCheckIn = async (childId) => {
    try {
      await checkInChild(childId);  // Call the context function to check in the child
      fetchCheckedInChildren();  // Re-fetch the updated checked-in children list
      toast.success('Child checked in successfully!');
    } catch (err) {
      toast.error('Error checking in child');
    }
  };

  // Handle check-out for a child
  const handleCheckOut = async (childId) => {
    try {
      await checkOutChild(childId);  // Call the context function to check out the child
      fetchCheckedInChildren();  // Re-fetch the updated checked-in children list
      toast.success('Child checked out successfully!');
    } catch (err) {
      toast.error('Error checking out child');
    }
  };

  // Check if the child is already checked in
  const isCheckedIn = (childId) => {
    return checkedInChildren.some((checkedInChild) => checkedInChild.child.id === childId);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <ToastContainer />
      
      {/* Code Input Section */}
      <div className="w-full md:w-1/3 bg-blue-500 p-6 flex flex-col justify-center items-center">
        <h2 className="text-2xl text-white mb-4">Enter 6-Digit Code</h2>
        <div className="bg-white p-4 rounded-md mb-4 text-3xl font-bold">
          {code.padEnd(6, '_')}
        </div>
        
        <div className="grid grid-cols-3 gap-4">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
            <button key={num} onClick={() => handleCodeInput(num)} className="bg-white p-4 rounded-lg text-xl">
              {num}
            </button>
          ))}
        </div>

        <div className="mt-4 space-x-2">
          <button onClick={handleSubmitCode} className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-lg">
            Submit Code
          </button>
          <button onClick={handleClearCode} className="bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-lg">
            Clear
          </button>
        </div>
      </div>

     {/* Children List Section */}
     <div className="w-full md:w-2/3 p-6 bg-gray-100">
        <h2 className="text-2xl font-bold mb-4">Children</h2>
        {error && <p className="text-red-500">{error}</p>}
        
        {children.length === 0 && <p>No children to display</p>}

        {children.map((child) => (
          <div key={child.id} className="bg-white p-4 rounded shadow mb-4">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-xl font-semibold">
                  {child.first_name} {child.last_name}
                </h3>
                <p>Born on: {child.date_of_birth}</p>
              </div>
              <div>
                {isCheckedIn(child.id) ? (  // Check if the child is checked in
                  <button onClick={() => handleCheckOut(child.id)} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg">
                    Check Out
                  </button>
                ) : (
                  <button onClick={() => handleCheckIn(child.id)} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                    Check In
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParentCheckInOut;