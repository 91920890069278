import React, { useState, useEffect } from 'react';
import { getCheckins } from '../services/api';

const Reporting = () => {
  const [checkins, setCheckins] = useState([]);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  useEffect(() => {
    fetchCheckins();
  }, [dateRange]);

  const fetchCheckins = async () => {
    try {
      const response = await getCheckins();
      let filteredCheckins = response.data;
      if (dateRange.start && dateRange.end) {
        filteredCheckins = filteredCheckins.filter(checkin => 
          checkin.check_in_date >= dateRange.start &&
          checkin.check_in_date <= dateRange.end
        );
      }
      setCheckins(filteredCheckins);
    } catch (error) {
      console.error('Error fetching check-ins:', error);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-2xl font-bold mb-4">Attendance Report</h2>
      
      {/* Date range picker inputs */}
      
      <table className="w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Child Name</th>
            <th className="border p-2">Check-In Date</th>
            <th className="border p-2">Check-In Time</th>
            <th className="border p-2">Check-Out Date</th>
            <th className="border p-2">Check-Out Time</th>
          </tr>
        </thead>
        <tbody>
          {checkins.map(checkin => (
            <tr key={checkin.id}>
              <td className="border p-2">{checkin.child.first_name} {checkin.child.last_name}</td>
              <td className="border p-2">{checkin.check_in_date}</td>
              <td className="border p-2">{checkin.check_in_time}</td>
              <td className="border p-2">{checkin.check_out_date || 'Not checked out'}</td>
              <td className="border p-2">{checkin.check_out_time || 'Not checked out'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Reporting;