import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Plus, Edit2, Trash2, X } from 'lucide-react';
import Header from '../components/Header';
import api from '../services/api';

const CampusManagement = () => {
    const [campuses, setCampuses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
    const [editingCampus, setEditingCampus] = useState(null);
    const [newCampus, setNewCampus] = useState({
        name: '',
        address: '',
        is_active: true
    });

    useEffect(() => {
        fetchCampuses();
    }, []);

    const fetchCampuses = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('campuses/');
            console.log(response);
            setCampuses(response.data);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewCampus(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('campuses/', newCampus);
            console.log(response);
            toast.success('Campus created successfully!');
            setNewCampus({ name: '', address: '', is_active: true });
            setIsRightPanelOpen(false);
            fetchCampuses();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleEdit = (campus) => {
        setEditingCampus(campus);
        setNewCampus(campus);
        setIsRightPanelOpen(true);
    };

    const handleUpdate = async () => {
        try {
            const response = await api.put(`campuses/${editingCampus.id}/`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCampus),
            });
            if (!response.ok) throw new Error('Failed to update campus');

            toast.success('Campus updated successfully!');
            setEditingCampus(null);
            setNewCampus({ name: '', address: '', is_active: true });
            setIsRightPanelOpen(false);
            fetchCampuses();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this campus?')) return;

        try {
            const response = await api.delete(`campuses/${id}/`, {
                method: 'DELETE',
            });
            if (!response.ok) throw new Error('Failed to delete campus');

            toast.success('Campus deleted successfully!');
            fetchCampuses();
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <>

            <div className="container mx-auto px-4 py-8">
                <ToastContainer />

                {/* Header Section */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Campus Management</h2>
                    <button
                        onClick={() => {
                            setEditingCampus(null);
                            setNewCampus({ name: '', address: '', is_active: true });
                            setIsRightPanelOpen(true);
                        }}
                        className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        <Plus size={20} />
                        Add New Campus
                    </button>
                </div>

                {/* Campuses Table */}
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow overflow-hidden">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {campuses.map(campus => (
                                        <tr key={campus.id} className="hover:bg-gray-50">
                                            <td className="px-6 py-4 whitespace-nowrap">{campus.name}</td>
                                            <td className="px-6 py-4">{campus.address}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 py-1 text-xs font-medium rounded-full ${campus.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                    }`}>
                                                    {campus.is_active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => handleEdit(campus)}
                                                        className="p-1 text-yellow-600 hover:text-yellow-700"
                                                    >
                                                        <Edit2 size={18} />
                                                    </button>
                                                    <button
                                                        onClick={() => handleDelete(campus.id)}
                                                        className="p-1 text-red-600 hover:text-red-700"
                                                    >
                                                        <Trash2 size={18} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Right Panel for Add/Edit Campus */}
                <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-lg transform ${isRightPanelOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
                    <div className="h-full flex flex-col p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-xl font-bold text-gray-800">
                                {editingCampus ? 'Edit Campus' : 'Add New Campus'}
                            </h3>
                            <button
                                onClick={() => setIsRightPanelOpen(false)}
                                className="p-2 hover:bg-gray-100 rounded-full"
                            >
                                <X size={20} />
                            </button>
                        </div>

                        <form onSubmit={editingCampus ? handleUpdate : handleSubmit} className="flex-1 flex flex-col gap-4">
                            <input
                                type="text"
                                name="name"
                                placeholder="Campus Name"
                                value={newCampus.name}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                required
                            />
                            <textarea
                                name="address"
                                placeholder="Address"
                                value={newCampus.address}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent h-32"
                                required
                            />
                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="is_active"
                                    name="is_active"
                                    checked={newCampus.is_active}
                                    onChange={handleInputChange}
                                    className="rounded border-gray-300"
                                />
                                <label htmlFor="is_active">Active</label>
                            </div>
                            <button
                                type="submit"
                                className="mt-auto bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition-colors"
                            >
                                {editingCampus ? 'Update Campus' : 'Create Campus'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampusManagement;