import React, { useState, useEffect } from 'react';
import { getChildren, addChild, deleteChild, updateChild } from '../services/api';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { X } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploader from '../components/ImageUploader';
import AuthorizedPersons from '../components/AuthorizedPersons';
import api from '../services/api';

const MyChildren = () => {
  const [children, setChildren] = useState([]);
  const [newChild, setNewChild] = useState({ first_name: '', last_name: '', date_of_birth: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedChildPicture, setSelectedChildPicture] = useState(null);
  const [editingChild, setEditingChild] = useState(null);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);

  useEffect(() => {
    fetchMyChildren();
  }, []);

  const fetchMyChildren = async () => {
    try {
      const response = await getChildren();
      setChildren(response.data);
      setError('');
    } catch (err) {
      console.error('Error fetching children:', err);
      setError(err.response?.data?.error || 'Failed to fetch children');
      toast.error('Failed to fetch children');
    }
  };

  const handleAddChild = async (e) => {
    e.preventDefault();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    if (new Date(newChild.date_of_birth) > oneYearAgo) {
      toast.error("Child's date of birth must be over one year from today's date.");
      return;
    }

    const formData = new FormData();
    formData.append('first_name', newChild.first_name);
    formData.append('last_name', newChild.last_name);
    formData.append('date_of_birth', newChild.date_of_birth);
    if (newChild.picture) {
      formData.append('picture', newChild.picture);
    }

    try {
      const response = await addChild(formData);
      await fetchMyChildren();
      toast.success('Child added successfully!');
      closeRightPanel();
    } catch (err) {
      console.error('Error adding child:', err);
      const errorMessage = err.response?.data?.error || 'Failed to add child';
      toast.error(errorMessage);
    }
  };

  const openRightPanel = (child = null) => {
    if (child) {
      setEditingChild(child);
      setNewChild({
        first_name: child.first_name,
        last_name: child.last_name,
        date_of_birth: child.date_of_birth
      });
    } else {
      setEditingChild(null);
      setNewChild({ first_name: '', last_name: '', date_of_birth: '' });
    }
    setIsRightPanelOpen(true);
  };

  const closeRightPanel = () => {
    setIsRightPanelOpen(false);
    setEditingChild(null);
    setNewChild({ first_name: '', last_name: '', date_of_birth: '' });
  };

  const handleUpdateChildImage = async (childId, file) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('picture', file);

    try {
      await api.patch(`parents/update_child/${childId}/`, formData, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchMyChildren();
      toast.success('Photo updated successfully!');
    } catch (err) {
      console.error('Error updating photo:', err);
      toast.error('Failed to update photo');
    }
  };

  const handleImageUpload = (file) => {
    setNewChild({ ...newChild, picture: file });
  };

  const handleDeleteChild = async (childId) => {
    if (!window.confirm('Are you sure you want to delete this child?')) return;

    try {
      await api.delete(`children/${childId}/`);
      await fetchMyChildren();
      toast.success('Child deleted successfully!');
    } catch (err) {
      console.error('Error deleting child:', err);
      toast.error('Failed to delete child');
    }
  };

  const handleEditChild = async (e) => {
    e.preventDefault();
    if (!editingChild) return;

    const formData = new FormData();
    formData.append('first_name', newChild.first_name);
    formData.append('last_name', newChild.last_name);
    formData.append('date_of_birth', newChild.date_of_birth);

    try {
      await api.put(`parents/update_child/${editingChild.id}/`, formData);
      await fetchMyChildren();
      toast.success('Child updated successfully!');
      closeRightPanel();
    } catch (err) {
      console.error('Error updating child:', err);
      toast.error('Failed to update child');
    }
  };

  return (
    <div className="relative min-h-screen">
      <div className="max-w-4xl mx-auto p-4">
        <ToastContainer />
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">My Children</h2>
          <button
            onClick={() => openRightPanel()}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Add Child
          </button>
        </div>

        <div className="space-y-4">
          {children.map((child) => (
            <div key={child.id} className="bg-white p-6 rounded-lg shadow">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 w-full sm:w-auto">
                  <div className="w-32">
                    <ImageUploader
                      existingImage={child.picture_url}
                      onImageUpload={(file) => handleUpdateChildImage(child.id, file)}
                    />
                  </div>
                  <div className="text-center sm:text-left">
                    <p className="text-lg font-semibold">{child.first_name} {child.last_name}</p>
                    <p className="text-sm text-gray-500">Born on: {child.date_of_birth}</p>
                  </div>
                </div>
                <div className="flex justify-center mt-4 sm:mt-0 space-x-4">
                  <button onClick={() => openRightPanel(child)} className="text-blue-500 hover:text-blue-700">
                    <FaEdit size={20} />
                  </button>
                  <button onClick={() => handleDeleteChild(child.id)} className="text-red-500 hover:text-red-700">
                    <FaTrash size={20} />
                  </button>
                </div>
              </div>
              <AuthorizedPersons childId={child.id} />
            </div>
          ))}
        </div>
      </div>

      {/* Right Panel for Add/Edit Child */}
      <div
        className={`fixed inset-y-0 right-0 w-full md:w-[600px] bg-white shadow-lg transform ${isRightPanelOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 ease-in-out z-40 overflow-y-auto`}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold">{editingChild ? 'Edit Child' : 'Add Child'}</h3>
            <button
              onClick={closeRightPanel}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X size={20} />
            </button>
          </div>

          <form onSubmit={editingChild ? handleEditChild : handleAddChild} className="space-y-6">
            <div className="flex justify-center mb-6">
              <ImageUploader
                existingImage={editingChild?.picture_url}
                onImageUpload={handleImageUpload}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="First Name"
                value={newChild.first_name}
                onChange={(e) => setNewChild({ ...newChild, first_name: e.target.value })}
                required
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={newChild.last_name}
                onChange={(e) => setNewChild({ ...newChild, last_name: e.target.value })}
                required
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <input
              type="date"
              value={newChild.date_of_birth}
              onChange={(e) => setNewChild({ ...newChild, date_of_birth: e.target.value })}
              required
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              max={new Date().toISOString().split('T')[0]}
              title="Child's date of birth must be over one year from today's date."
            />

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              {editingChild ? 'Update Child' : 'Add Child'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyChildren;