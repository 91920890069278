import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = 'http://localhost:8000/api/';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`, // Dynamically fetch the token
    },
  });
  

const CheckInContext = createContext();

export const useCheckInContext = () => useContext(CheckInContext);

export const CheckInProvider = ({ children }) => {
  const [checkedInChildren, setCheckedInChildren] = useState([]);

  // Context: After check-in/check-out, update the global state
const fetchCheckedInChildren = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}children/checked_in/`);
        setCheckedInChildren(response.data);  // This updates the global state
    } catch (error) {
        console.error('Error fetching checked-in children:', error);
    }
};

// Example use in component:
const handleCheckIn = async (childId) => {
    try {
        await checkInChild(childId);
        fetchCheckedInChildren();  // Fetch updated checked-in children
        toast.success('Child checked in!');
    } catch (err) {
        toast.error('Error checking in child');
    }
};

  // Check in a child and update state
  const checkInChild = async (childId) => {
    try {
      await axiosInstance.post(`${API_URL}children/${childId}/check_in/`);
      fetchCheckedInChildren(); // Sync the state after checking in
    } catch (err) {
      console.error('Error checking in child:', err);
    }
  };

  // Check out a child and update state
  const checkOutChild = async (childId) => {
    try {
      await axiosInstance.post(`${API_URL}children/${childId}/check_out/`);
      fetchCheckedInChildren(); // Sync the state after checking out
    } catch (err) {
      console.error('Error checking out child:', err);
    }
  };

  useEffect(() => {
    fetchCheckedInChildren(); // Fetch the checked-in children when the component loads
  }, []);

  return (
    <CheckInContext.Provider
      value={{
        checkedInChildren,
        fetchCheckedInChildren, // Ensure this function is passed to components
        checkInChild,
        checkOutChild,
      }}
    >
      {children}
    </CheckInContext.Provider>
  );
};
