import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, X, Search } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../services/api';
import { getStoredUser } from '../../services/auth';
import CommunicationForm from './CommunicationForm';
import { useAuth } from '../../contexts/AuthContext';

const CommunicationList = () => {
    const [communications, setCommunications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('ALL');
    const [searchTerm, setSearchTerm] = useState('');
    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
    const [editingCommunication, setEditingCommunication] = useState(null);
    const { user, refreshUser } = useAuth();
    const [currentUser, setCurrentUser] = useState(null);



    // Separate effect for fetching communications
    useEffect(() => {
        if (currentUser) {
            fetchCommunications();
        }
    }, [filter, currentUser]);

    const fetchCommunications = async () => {
        try {
            const response = await api.get(`communications/${filter === 'UNREAD' ? 'unread/' : ''}`);
            setCommunications(response.data || []); // Ensure we always have an array
        } catch (err) {
            setError(err.message);
            toast.error('Failed to fetch communications');
        } finally {
            setLoading(false);
        }
    };

    const markAsRead = async (id) => {
        try {
            await api.post(`communications/${id}/mark_as_read/`);
            await fetchCommunications();
            toast.success('Marked as read');
        } catch (err) {
            console.error('Error marking as read:', err);
            toast.error('Failed to mark as read');
        }
    };

    const handleOpenForm = (communication = null) => {
        setEditingCommunication(communication);
        setIsRightPanelOpen(true);
    };

    const handleCloseForm = () => {
        setEditingCommunication(null);
        setIsRightPanelOpen(false);
    };

    // Direct role checks without intermediate variables
    const isSuperuserOrLeader = Boolean(
        currentUser && ['SUPERUSER', 'LEADER'].includes(currentUser.role)
    );

    const canViewCommunications = Boolean(
        currentUser && ['SUPERUSER', 'LEADER', 'PARENT'].includes(currentUser.role)
    );

    const filteredCommunications = communications.filter(comm => {
        if (!canViewCommunications) return false;

        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            return (
                comm.title?.toLowerCase().includes(searchLower) ||
                comm.message?.toLowerCase().includes(searchLower) ||
                comm.sender_name?.toLowerCase().includes(searchLower) ||
                comm.event_name?.toLowerCase().includes(searchLower) ||
                comm.communication_type?.toLowerCase().includes(searchLower)
            );
        }
        return true;
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!canViewCommunications) return <div>You don't have permission to view communications.</div>;

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <div className="mb-6">
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">Communications</h1>

                    {/* Debug information */}
                    <div className="text-xs text-gray-500">
                        Role: {currentUser?.role || 'None'} |
                        Can Create: {isSuperuserOrLeader ? 'Yes' : 'No'}
                    </div>

                    {/* Render button directly based on role check */}
                    {currentUser?.role === 'SUPERUSER' && (
                        <button
                            onClick={() => handleOpenForm()}
                            className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <Plus size={20} />
                            New Communication
                        </button>
                    )}
                </div>

                <div className="flex flex-col md:flex-row gap-4 mb-4">
                    <div className="flex gap-4">
                        <button
                            onClick={() => setFilter('ALL')}
                            className={`px-4 py-2 rounded ${filter === 'ALL' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        >
                            All
                        </button>
                        <button
                            onClick={() => setFilter('UNREAD')}
                            className={`px-4 py-2 rounded ${filter === 'UNREAD' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        >
                            Unread
                        </button>
                    </div>

                    <div className="relative flex-1 max-w-md">
                        <input
                            type="text"
                            placeholder="Search communications..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
                    </div>
                </div>
            </div>

            <div className="space-y-4">
                {filteredCommunications.length > 0 ? (
                    filteredCommunications.map((comm) => (
                        comm && (
                            <div
                                key={comm.id}
                                className={`bg-white rounded-lg shadow-md p-6 ${!comm.is_read ? 'border-l-4 border-blue-500' : ''}`}
                            >
                                <div className="flex justify-between items-start mb-2">
                                    <h2 className="text-xl font-semibold">{comm.title}</h2>
                                    <span className="text-sm text-gray-500">
                                        {new Date(comm.created_at).toLocaleDateString()}
                                    </span>
                                </div>

                                <div className="mb-4">
                                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                                        {comm.communication_type}
                                    </span>
                                    {comm.event_name && (
                                        <span className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700">
                                            {comm.event_name}
                                        </span>
                                    )}
                                </div>

                                <p className="text-gray-700 mb-4">{comm.message}</p>

                                <div className="flex justify-between items-center">
                                    <div className="text-sm text-gray-600">
                                        From: {comm.sender_name}
                                    </div>
                                    <div className="flex gap-2">
                                        {comm.attachment_url && (
                                            <a
                                                href={comm.attachment_url}
                                                className="text-blue-500 hover:text-blue-700"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View Attachment
                                            </a>
                                        )}
                                        {!comm.is_read && (
                                            <button
                                                onClick={() => markAsRead(comm.id)}
                                                className="text-green-500 hover:text-green-700"
                                            >
                                                Mark as Read
                                            </button>
                                        )}
                                        {isSuperuserOrLeader && (
                                            <button
                                                onClick={() => handleOpenForm(comm)}
                                                className="text-blue-500 hover:text-blue-700"
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    ))
                ) : (
                    <div className="text-center text-gray-500 py-8">
                        No communications found
                    </div>
                )}
            </div>

            {isRightPanelOpen && (
                <CommunicationForm
                    isOpen={isRightPanelOpen}
                    onClose={handleCloseForm}
                    communication={editingCommunication}
                    onSuccess={fetchCommunications}
                    currentUser={currentUser}
                />
            )}
        </div>
    );
};

export default CommunicationList;