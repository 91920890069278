import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const RoleBasedRoute = ({ allowedRoles, children }) => {
  const { user, loading, hasRole } = useAuth();

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!hasRole(allowedRoles)) {
    console.log('User roles:', user.role);
    console.log('Required roles:', allowedRoles);
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default RoleBasedRoute;