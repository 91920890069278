// src/components/ImageUploader.jsx
import React, { useState, useRef } from 'react';
import { Button } from "./ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "./ui/dialog";
import { Camera, X } from 'lucide-react';

const ImageUploader = ({ onImageUpload, existingImage = null }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(existingImage);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef(null);

    const processImage = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const img = new Image();
                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // Maximum dimensions
                    const MAX_WIDTH = 800;
                    const MAX_HEIGHT = 800;

                    // Maintain aspect ratio while resizing
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to Blob
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name, {
                            type: 'image/jpeg',
                            lastModified: Date.now(),
                        }));
                    }, 'image/jpeg', 0.8);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const handleImageSelection = async (file) => {
        try {
            setIsUploading(true);
            const processedFile = await processImage(file);
            const previewUrl = URL.createObjectURL(processedFile);
            setPreviewUrl(previewUrl);
            setIsModalOpen(false);

            if (onImageUpload) {
                await onImageUpload(processedFile);
            }
        } catch (error) {
            console.error('Error processing image:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleFileInput = async (e) => {
        const file = e.target.files?.[0];
        if (file) {
            handleImageSelection(file);
        }
    };

    const removeImage = () => {
        setPreviewUrl(null);
        if (onImageUpload) {
            onImageUpload(null);
        }
    };

    return (
        <div className="w-full">
            {previewUrl ? (
                <div className="relative w-32 h-32 mx-auto">
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className="w-full h-full object-cover rounded-full shadow-lg"
                    />
                    <button
                        onClick={removeImage}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
                    >
                        <X className="w-4 h-4" />
                    </button>
                </div>
            ) : (
                <Button
                    onClick={() => setIsModalOpen(true)}
                    variant="outline"
                    className="w-32 h-32 rounded-full mx-auto flex flex-col items-center justify-center bg-gray-50 border-2 border-dashed border-gray-300 hover:border-blue-500 hover:bg-gray-100 transition-all"
                >
                    <Camera className="w-8 h-8 text-gray-400" />
                    <span className="text-sm text-gray-500 mt-2">Add Photo</span>
                </Button>
            )}

            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Upload Photo</DialogTitle>
                    </DialogHeader>
                    <div className="w-full space-y-4">
                        <div className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg bg-gray-50">
                            <Camera className="w-12 h-12 text-gray-400 mb-4" />
                            <p className="text-sm text-gray-500 text-center mb-4">
                                Click the button below to select an image
                            </p>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileInput}
                                accept="image/*"
                                className="hidden"
                            />
                            <Button
                                onClick={() => fileInputRef.current?.click()}
                                disabled={isUploading}
                                className="min-w-[200px]"
                            >
                                {isUploading ? 'Processing...' : 'Select Image'}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ImageUploader;