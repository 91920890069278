import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { MapPin, Calendar, Clock, Users } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';

const EventRegistrationDetail = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [children, setChildren] = useState([]);
    const [registrations, setRegistrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const { user, refreshUser } = useAuth();

    useEffect(() => {
        setCurrentUser(user);
        fetchEventDetails();
        fetchUserChildren(user.id);
        fetchRegistrations();
    }, [eventId]);

    const fetchEventDetails = async () => {
        try {
            const response = await api.get(`events/${eventId}/`);
            setEvent(response.data);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to fetch event details');
            toast.error('Failed to fetch event details');
        }
    };

    const fetchUserChildren = async (userId) => {
        try {
            const response = await api.get(`children/?parent=${userId}`);
            setChildren(response.data);
        } catch (err) {
            console.error('Error fetching children:', err);
            toast.error('Failed to fetch children');
        }
    };

    const fetchRegistrations = async () => {
        try {
            const response = await api.get(`event-registrations/?event=${eventId}`);
            setRegistrations(response.data);
        } catch (err) {
            console.error('Error fetching registrations:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleRegister = async (childId, registerForAll = false) => {
        try {
            // Check if child meets age requirements
            const child = children.find(c => c.id === childId);
            const childAge = calculateAge(child.date_of_birth);

            if (event.minimum_age && childAge < event.minimum_age) {
                toast.error(`Child must be at least ${event.minimum_age} years old`);
                return;
            }
            if (event.maximum_age && childAge > event.maximum_age) {
                toast.error(`Child must be under ${event.maximum_age} years old`);
                return;
            }

            const response = await api.post('event-registrations/', {
                event_id: event.id,
                child_id: childId,
                register_for_all_occurrences: registerForAll
            });

            toast.success(response.data.message || 'Registration successful!');
            fetchRegistrations();
        } catch (err) {
            const errorMessage = err.response?.data?.error || 'Registration failed';
            toast.error(errorMessage);
        }
    };

    const handleCancelRegistration = async (registrationId) => {
        try {
            await api.delete(`event-registrations/${registrationId}/`);
            toast.success('Registration cancelled successfully');
            fetchRegistrations();
        } catch (err) {
            toast.error('Failed to cancel registration');
        }
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!event) return <div>Event not found</div>;

    return (
        <div className="container mx-auto px-4 py-8">
            <ToastContainer />

            {/* Event Details Section */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <div className="flex justify-between items-start mb-6">
                    <div>
                        <h1 className="text-3xl font-bold text-gray-800 mb-2">{event.name}</h1>
                        <p className="text-gray-600">{event.description}</p>
                    </div>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${event.status === 'PUBLISHED' ? 'bg-green-100 text-green-800' :
                        event.status === 'DRAFT' ? 'bg-yellow-100 text-yellow-800' :
                            event.status === 'CANCELLED' ? 'bg-red-100 text-red-800' :
                                'bg-gray-100 text-gray-800'
                        }`}>
                        {event.status}
                    </span>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                        <div className="flex items-center gap-2 text-gray-600">
                            <Calendar size={20} />
                            <span>{format(new Date(event.start_date), 'MMMM d, yyyy')}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-600">
                            <Clock size={20} />
                            <span>{format(new Date(`2000-01-01T${event.start_time}`), 'h:mm a')} - {format(new Date(`2000-01-01T${event.end_time}`), 'h:mm a')}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-600">
                            <MapPin size={20} />
                            <span>{event.custom_address || event.campus_name}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-600">
                            <Users size={20} />
                            <span>{event.registered_count} / {event.capacity || 'Unlimited'} Registered</span>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {event.minimum_age && (
                            <p className="text-gray-600">Minimum Age: {event.minimum_age} years</p>
                        )}
                        {event.maximum_age && (
                            <p className="text-gray-600">Maximum Age: {event.maximum_age} years</p>
                        )}
                        {event.registration_deadline && (
                            <p className="text-gray-600">
                                Registration Deadline: {format(new Date(event.registration_deadline), 'MMMM d, yyyy')}
                            </p>
                        )}
                    </div>
                </div>
            </div>

            {/* Registration Section */}
            {currentUser && (currentUser.role === 'PARENT' || currentUser.is_superuser) && (
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-xl font-bold text-gray-800 mb-4">Register Children</h2>

                    {children.length > 0 ? (
                        <div className="space-y-4">
                            {children.map(child => {
                                const existingRegistration = registrations.find(reg => reg.child === child.id);
                                const isEligible = (!event.minimum_age || calculateAge(child.date_of_birth) >= event.minimum_age) &&
                                    (!event.maximum_age || calculateAge(child.date_of_birth) <= event.maximum_age);

                                return (
                                    <div key={child.id} className="flex items-center justify-between p-4 border rounded-lg">
                                        <div>
                                            <p className="font-medium">{child.first_name} {child.last_name}</p>
                                            <p className="text-sm text-gray-500">Age: {calculateAge(child.date_of_birth)} years</p>
                                        </div>

                                        {!isEligible ? (
                                            <p className="text-sm text-red-500">Age requirements not met</p>
                                        ) : existingRegistration ? (
                                            <button
                                                onClick={() => handleCancelRegistration(existingRegistration.id)}
                                                className="px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors"
                                            >
                                                Cancel Registration
                                            </button>
                                        ) : (
                                            <div className="space-x-2">
                                                <button
                                                    onClick={() => handleRegister(child.id, false)}
                                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                                                >
                                                    Register
                                                </button>
                                                {event.frequency !== 'ONCE' && (
                                                    <button
                                                        onClick={() => handleRegister(child.id, true)}
                                                        className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                                                    >
                                                        Register for All
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p className="text-gray-500">No children found. Please add children to your profile first.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default EventRegistrationDetail;