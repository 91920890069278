import React, { useState, useEffect } from 'react';
import { getMyChildren } from '../services/api';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import api from '../services/api';
import { getCurrentUser } from '../services/auth';

const Children = () => {
  const navigate = useNavigate();
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState('');
  const [campuses, setCampuses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUserRole();
    fetchMyChildren();
  }, []);

  const fetchUserRole = async () => {
    try {
      const userData = await getCurrentUser();
      setUserRole({
        isSuperuser: userData.role === 'SUPERUSER',
        isCampusStaff: userData.role === 'LEADER',
        isParent: userData.role === 'PARENT',
        email: userData.email
      });
    } catch (err) {
      console.error('Error fetching user role:', err);
    }
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const filteredChildren = children.filter(child => {
    const matchesSearch = (child.first_name + ' ' + child.last_name)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCampus = !selectedCampus || child.campus === selectedCampus;
    return matchesSearch && matchesCampus;
  });

  const fetchMyChildren = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('children/');
      setChildren(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching children:', err);
      toast.error(err.response?.data?.error || 'Failed to fetch children');
      setIsLoading(false);
    }
  };

  const handleChildClick = (childId) => {
    navigate(`${childId}/`);
  };

  const getPageTitle = () => {
    if (userRole?.isSuperuser) return 'All Children (All Campuses)';
    if (userRole?.isCampusStaff) return `Children at ${userRole.campusName}`;
    return 'My Children';
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800">{getPageTitle()}</h1>
        {/* Search and Filter Section */}
        <div className="mb-6 flex flex-wrap gap-4">
          <input
            type="text"
            placeholder="Search by name..."
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            value={selectedCampus}
            onChange={(e) => setSelectedCampus(e.target.value)}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Campuses</option>
            {campuses.map(campus => (
              <option key={campus.id} value={campus.id}>{campus.name}</option>
            ))}
          </select>
        </div>


        {userRole?.isSuperuser && (
          <div className="mb-4 p-4 bg-blue-50 rounded-lg">
            <p className="text-blue-700">Viewing all children across all campuses (Superuser Access)</p>
          </div>
        )}

        {userRole?.isCampusStaff && (
          <div className="mb-4 p-4 bg-green-50 rounded-lg">
            <p className="text-green-700">Viewing children at your campus</p>
          </div>
        )}

        {isLoading ? (
          <div className="text-center">
            <div className="spinner"></div>
            <p className="mt-2 text-gray-600">Loading...</p>
          </div>
        ) : filteredChildren.length === 0 ? (
          <p className="text-gray-600 text-center">No children found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredChildren.map(child => (
              <div
                key={child.id}
                className="bg-white rounded-lg shadow-md p-6 cursor-pointer transform transition-transform hover:scale-105"
                onClick={() => handleChildClick(child.id)}
              >
                {child.picture_url && (
                  <img
                    src={child.picture_url}
                    alt={`${child.first_name} ${child.last_name}`}
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                )}
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  {child.first_name} {child.last_name}
                </h2>
                <p className="text-gray-600">Age: {calculateAge(child.date_of_birth)} years old</p>
                {(userRole?.isSuperuser || userRole?.isLeader || userRole?.isStaff) && (
                  <p className="text-gray-600 mt-2">
                    Campus: {child.campus_name || 'N/A'}<br />
                    Parent: {child.parent_full_name || 'N/A'}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Children;