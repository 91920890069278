import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import api from '../services/api';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../contexts/AuthContext';

const DashboardCard = ({ to, bgColor, title, description }) => (
  <Link
    to={to}
    className={`${bgColor} text-white rounded-lg p-6 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1`}
  >
    <h2 className="text-2xl font-bold mb-2">{title}</h2>
    <p>{description}</p>
  </Link>
);

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [communications, setCommunications] = useState([]);
  const [events, setEvents] = useState([]);
  const { user, refreshUser } = useAuth();

  useEffect(() => {
    setCurrentUser(user);

    if (user && (user.role === 'PARENT' || user.is_superuser)) {
      fetchCommunications(user.id);
      fetchEvents();
      fetchData(user);
    }
  }, []);

  const fetchData = async (user) => {
    if (!user) return;
    try {
      const commResponse = await api.get('communications/');
      setCommunications(commResponse.data);
    } catch (error) {
      console.error('Error fetching communications:', error);
      toast.error('Failed to fetch communications');
    }

    try {
      const eventResponse = await api.get('events/');
      setEvents(eventResponse.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Failed to fetch events');
    }
  };

  const fetchCommunications = async (userId) => {
    try {
      const response = await api.get(`communications/?user=${userId}`);
      setCommunications(response.data);
    } catch (error) {
      console.error('Error fetching communications:', error);
      toast.error('Failed to fetch communications');
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await api.get('events/');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Failed to fetch events');
    }
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  // Define unique dashboard cards for superuser
  const superuserCards = [
    // Reporting
    { to: "/management", bgColor: "bg-purple-400 hover:bg-purple-500", title: "Management", description: "Manage every components" },
    // Children Management
    { to: "/children", bgColor: "bg-yellow-400 hover:bg-yellow-500", title: "Manage Children", description: "View and manage all children's information" },
    // Check-in Management
    { to: "/checkin", bgColor: "bg-green-400 hover:bg-green-500", title: "Check In/Out", description: "Manage child check-ins and check-outs" },
    // Lesson Management
    { to: "/lessons", bgColor: "bg-indigo-400 hover:bg-indigo-500", title: "Lessons", description: "View and manage lesson materials for events" },
    // Event Management
    { to: "/events/manage", bgColor: "bg-blue-400 hover:bg-blue-500", title: "Event Management", description: "Create and manage events" },
    // Communications Management
    { to: "/communications/", bgColor: "bg-pink-400 hover:bg-pink-500", title: "Manage Communications", description: "Create and manage communications" }
  ];

  // Original role-specific cards for other roles
  const dashboardCards = {
    STAFF: [
      { to: "/children", bgColor: "bg-yellow-400 hover:bg-yellow-500", title: "Manage Children", description: "View and manage all children's information" },
      { to: "/checkin", bgColor: "bg-green-400 hover:bg-green-500", title: "Check In/Out", description: "Manage child check-ins and check-outs" },
      { to: "/lessons", bgColor: "bg-indigo-400 hover:bg-indigo-500", title: "Lessons", description: "View and manage lesson materials for events" }
    ],
    LEADER: [
      { to: "/reports", bgColor: "bg-purple-400 hover:bg-purple-500", title: "Reports", description: "View and generate attendance reports" },
      { to: "/events/manage", bgColor: "bg-blue-400 hover:bg-blue-500", title: "Event Management", description: "Create and manage events" },
      { to: "/communications/", bgColor: "bg-pink-400 hover:bg-pink-500", title: "Manage Communications", description: "Create and manage communications" }
    ],
    PARENT: [
      { to: "/my-children", bgColor: "bg-blue-400 hover:bg-blue-500", title: "My Children", description: "View your children's information" },
      { to: "/event-registration", bgColor: "bg-teal-400 hover:bg-teal-500", title: "Event Registration", description: "Register your children for upcoming events" },
      { to: "/communications/", bgColor: "bg-pink-400 hover:bg-pink-500", title: "Communications", description: "View messages and notifications" }
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <ToastContainer />
      <main className="container mx-auto mt-8 p-4">
        <h1 className="text-4xl font-bold text-blue-600 mb-8">
          Welcome to TG Children Ministry, {currentUser.username}!
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Render cards based on user role */}
          {currentUser.is_superuser ? (
            // Superuser sees consolidated unique cards
            superuserCards.map((card, index) => (
              <DashboardCard key={index} {...card} />
            ))
          ) : (
            // Other users see their role-specific cards
            <>
              {/* Staff cards */}
              {currentUser.role === 'STAFF' && dashboardCards.STAFF.map((card, index) => (
                <DashboardCard key={index} {...card} />
              ))}
              {/* Leader cards */}
              {currentUser.role === 'LEADER' && (
                <>
                  {dashboardCards.STAFF.map((card, index) => (
                    <DashboardCard key={`staff-${index}`} {...card} />
                  ))}
                  {dashboardCards.LEADER.map((card, index) => (
                    <DashboardCard key={`leader-${index}`} {...card} />
                  ))}
                </>
              )}
              {/* Parent cards */}
              {currentUser.role === 'PARENT' && dashboardCards.PARENT.map((card, index) => (
                <DashboardCard key={index} {...card} />
              ))}
            </>
          )}
        </div>

        {/* Parent-specific sections */}
        {(currentUser.is_superuser || currentUser.role === 'PARENT') && (
          <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Communications Section */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">Recent Communications</h2>
                {(currentUser.is_superuser || currentUser.role === 'LEADER') && (
                  <Link
                    to="/communications/create"
                    className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors"
                  >
                    New Communication
                  </Link>
                )}
              </div>
              <div className="space-y-4">
                {communications.length > 0 ? (
                  communications.map((comm) => (
                    <div key={comm.id} className="border-b border-gray-200 pb-4">
                      <div className="flex justify-between items-start">
                        <h3 className="font-semibold text-lg">{comm.title}</h3>
                        {(currentUser.is_superuser || currentUser.role === 'LEADER') && (
                          <Link
                            to={`/communications/edit/${comm.id}`}
                            className="text-sm text-blue-500 hover:text-blue-700"
                          >
                            Edit
                          </Link>
                        )}
                      </div>
                      <p className="text-gray-600">{comm.message}</p>
                      <div className="flex justify-between mt-2">
                        <span className="text-sm text-gray-500">
                          {new Date(comm.created_at).toLocaleDateString()}
                        </span>
                        {comm.attachment && (
                          <a
                            href={comm.attachment}
                            className="text-blue-500 hover:text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Attachment
                          </a>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No recent communications</p>
                )}
              </div>
            </div>

            {/* Upcoming Events Section */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Upcoming Events</h2>
              <div className="space-y-4">
                {events.length > 0 ? (
                  events.map((event) => (
                    <div key={event.id} className="border-b border-gray-200 pb-4">
                      <h3 className="font-semibold text-lg">{event.name}</h3>
                      <p className="text-gray-600">{event.description}</p>
                      <div className="flex justify-between mt-2">
                        <span className="text-sm text-gray-500">
                          {new Date(event.start_date).toLocaleDateString()}
                        </span>
                        <Link
                          to={`/event-registration/${event.id}`}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          Register
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No upcoming events</p>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Dashboard;