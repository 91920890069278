import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout } = useAuth();

  // If there's no user, don't render the header
  if (!user) return null;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path ? 'text-yellow-300 font-bold' : 'hover:text-blue-200';
  };

  return (
    <header className="sticky top-0 z-50 bg-blue-500 text-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/dashboard" className="text-2xl font-bold">TG Kids</Link>

          {/* Hamburger Menu for Mobile */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-16 6h16" />
            </svg>
          </button>

          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-4">
            <Link to="/dashboard" className={isActive('/dashboard')}>Dashboard</Link>
            {(user.is_superuser || ['LEADER', 'STAFF'].includes(user.role)) && (
              <>
                <Link to="/children" className={isActive('/children')}>Children</Link>
                <Link to="/checkin" className={isActive('/checkin')}>Check In/Out</Link>
              </>
            )}
            {(user.is_superuser || user.role === 'LEADER') && (
              <Link to="/reports" className={isActive('/reports')}>Reports</Link>
            )}
            {user.is_superuser && (
              <Link to="/management" className={isActive('/management')}>Management</Link>
            )}
            {user.role === 'PARENT' && (
              <Link to="/my-children" className={isActive('/my-children')}>My Children</Link>
            )}
          </nav>

          {/* User Menu (Desktop) */}
          <div className="hidden md:block relative">
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className="flex items-center space-x-1"
            >
              <span>{user.username}</span>
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                {user.role === 'PARENT' && (
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={() => setShowDropdown(false)}
                  >
                    Profile
                  </Link>
                )}
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    handleLogout();
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden py-2 border-t border-blue-400">
            <Link
              to="/dashboard"
              className="block py-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Dashboard
            </Link>
            {(user.is_superuser || ['LEADER', 'STAFF'].includes(user.role)) && (
              <>
                <Link
                  to="/children"
                  className="block py-2"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Children
                </Link>
                <Link
                  to="/checkin"
                  className="block py-2"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Check In/Out
                </Link>
              </>
            )}
            {(user.is_superuser || user.role === 'LEADER') && (
              <Link
                to="/reports"
                className="block py-2"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Reports
              </Link>
            )}
            {user.is_superuser && (
              <Link
                to="/management"
                className="block py-2"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Management
              </Link>
            )}
            {user.role === 'PARENT' && (
              <Link
                to="/my-children"
                className="block py-2"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                My Children
              </Link>
            )}
            {user.role === 'PARENT' && (
              <Link
                to="/profile"
                className="block py-2"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Profile
              </Link>
            )}
            <button
              onClick={() => {
                setIsMobileMenuOpen(false);
                handleLogout();
              }}
              className="block w-full text-left py-2"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;