import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { generateCheckInCode, getCheckInCode } from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';

const ProfilePage = () => {
  const [checkInCode, setCheckInCode] = useState('');
  //const [user, setUser] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const { user, refreshUser } = useAuth();

  useEffect(() => {

    setCurrentUser(user);
    if (user) {
      fetchCheckInCode(user.id); // Fetch the check-in code from the DB when the component loads
    }
  }, []);

  // Function to fetch the check-in code from the backend
  const fetchCheckInCode = async (userId) => {
    try {
      const response = await getCheckInCode(userId); // Fetch check-in code from the backend API
      setCheckInCode(response.data.check_in_code); // Set the fetched code in the state
    } catch (err) {
      console.error('Error fetching check-in code:', err);
      toast.error('Error fetching 6-digit code');
    }
  };

  const handleGenerateCode = async () => {
    try {
      // Make sure you pass the correct parent ID here
      const response = await generateCheckInCode(currentUser.id);  // Use user.id or user.parent_id
      setCheckInCode(response.data.check_in_code);
      toast.success('New 6-digit code generated!');
    } catch (err) {
      toast.error('Error generating 6-digit code');
    }
  };

  return (
    <>
      <Header />
      <div className="max-w-xl mx-auto p-4">
        <ToastContainer />
        <h2 className="text-2xl font-bold mb-4">Profile</h2>

        {/* Display Current Check-In Code */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Your 6-Digit Check-In Code</h3>
          <p>{checkInCode ? checkInCode : 'No code available yet'}</p>
        </div>

        {/* Button to Generate New Code */}
        <button
          onClick={handleGenerateCode}
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
        >
          Generate New 6-Digit Code
        </button>
      </div>
    </>
  );
};

export default ProfilePage;
