import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Book, FileText, Share2, Calendar, Users,
    Plus, Edit2, Trash2, Upload
} from 'lucide-react';
import { toast } from 'react-toastify';
import api from '../../services/api';
const LessonForm = ({ lesson, onSubmit, isEditing = false }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        content: '',
        campus: '',
        event: '',
        status: 'DRAFT',
        scheduled_date: '',
        ...lesson
    });

    const [attachments, setAttachments] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [campuses, setCampuses] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchCampuses();
        if (lesson) {
            // Update formData and attachments when lesson changes
            setFormData({
                title: '',
                description: '',
                content: '',
                campus: '',
                event: '',
                status: 'DRAFT',
                scheduled_date: '',
                ...lesson
            });
            setAttachments(lesson.attachments || []);
        }
    }, [lesson]); // Add lesson as a dependency

    const fetchCampuses = async () => {
        try {
            const response = await api.get('campuses/');

            setCampuses(response.data);
        } catch (error) {
            toast.error('Failed to load campuses');
        }
    };

    const fetchEvents = async (campusId) => {
        try {
            const response = await api.get(`events/?campus=${campusId}`);
            setEvents(response.data);
        } catch (error) {
            toast.error('Failed to load events');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (name === 'campus') {
            fetchEvents(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onSubmit(formData);
        } catch (error) {
            toast.error('Failed to submit lesson');
        }
    };

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        setIsUploading(true);

        for (const file of files) {
            try {
                if (!lesson?.id) {
                    toast.error('Please save the lesson first before adding attachments');
                    continue;
                }

                // Step 1: Get presigned URL
                const presignedUrlResponse = await api.post('file-upload/', {
                    fileName: file.name,
                    fileType: file.type,
                    directory: 'lessons'
                });

                if (!presignedUrlResponse.data?.url) {
                    throw new Error('Failed to get upload URL');
                }

                // Log the URL and file details for debugging
                console.log('Presigned URL:', presignedUrlResponse.data.url);
                console.log('File details:', {
                    name: file.name,
                    type: file.type,
                    size: file.size
                });

                // Step 2: Upload to S3
                const uploadResponse = await fetch(presignedUrlResponse.data.url, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type
                    }
                });

                if (!uploadResponse.ok) {
                    console.error('S3 Upload Error:', await uploadResponse.text());
                    throw new Error(`Failed to upload to S3: ${uploadResponse.statusText}`);
                }

                // Step 3: Notify backend about successful upload
                const completeResponse = await api.post('file-upload/complete/', {
                    fileKey: presignedUrlResponse.data.fileKey,
                    modelType: 'lesson',
                    modelId: lesson.id,
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size
                });

                if (completeResponse.data?.status === 'success') {
                    const newAttachment = {
                        id: completeResponse.data.attachmentId || presignedUrlResponse.data.fileKey,
                        file_name: file.name,
                        file_type: file.type,
                        file_size: file.size,
                        file_key: presignedUrlResponse.data.fileKey
                    };

                    setAttachments(prev => [...prev, newAttachment]);
                    toast.success(`${file.name} uploaded successfully`);
                }
            } catch (error) {
                console.error('Upload error:', error);
                toast.error(`Failed to upload ${file.name}: ${error.message}`);
            }
        }

        setIsUploading(false);
    };

    // Also update the remove attachment function
    const handleRemoveAttachment = async (attachmentId) => {
        try {
            await api.delete(`lessons/${lesson.id}/remove-attachment/${attachmentId}/`);
            setAttachments(prev => prev.filter(att => att.id !== attachmentId));
            toast.success('Attachment removed');
        } catch (error) {
            console.error('Remove error:', error);
            toast.error('Failed to remove attachment');
        }
    };

    const handleDownload = async (attachment) => {
        try {
            const response = await api.get(`lessons/${lesson.id}/attachments/${attachment.id}/download/`);
            if (response.data.url) {
                window.open(response.data.url, '_blank');
            }
        } catch (error) {
            console.error('Download error:', error);
            toast.error('Failed to download file');
        }
    };


    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-medium mb-4">Basic Information</h3>

                <div className="space-y-4">
                    {/* All existing input fields remain the same except removing age_group, notify_parents, and is_public */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg"
                            rows="3"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Content
                        </label>
                        <textarea
                            name="content"
                            value={formData.content}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg"
                            rows="6"
                            required
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Campus
                            </label>
                            <select
                                name="campus"
                                value={formData.campus}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg"
                                required
                            >
                                <option value="">Select Campus</option>
                                {campuses.map(campus => (
                                    <option key={campus.id} value={campus.id}>
                                        {campus.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Event (Optional)
                            </label>
                            <select
                                name="event"
                                value={formData.event}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg"
                            >
                                <option value="">Select Event</option>
                                {events.map(event => (
                                    <option key={event.id} value={event.id}>
                                        {event.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Status
                            </label>
                            <select
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg"
                                required
                            >
                                <option value="DRAFT">Draft</option>
                                <option value="SCHEDULED">Scheduled</option>
                                <option value="COMPLETED">Completed</option>
                                <option value="CANCELLED">Cancelled</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Scheduled Date
                            </label>
                            <input
                                type="date"
                                name="scheduled_date"
                                value={formData.scheduled_date}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg"
                                required={formData.status === 'SCHEDULED'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Attachment section - now always visible */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-medium mb-4">Attachments</h3>

                <div className="space-y-4">
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
                        <input
                            type="file"
                            onChange={handleFileUpload}
                            multiple
                            className="hidden"
                            id="file-upload"
                            accept=".pdf,.doc,.docx,.ppt,.pptx"
                        />
                        <label
                            htmlFor="file-upload"
                            className="flex flex-col items-center justify-center cursor-pointer"
                        >
                            <Upload size={24} className="text-gray-400 mb-2" />
                            <span className="text-sm text-gray-600">
                                Click to upload files or drag and drop
                            </span>
                            <span className="text-xs text-gray-500 mt-1">
                                PDF, DOC, DOCX, PPT, PPTX up to 10MB
                            </span>
                        </label>
                    </div>

                    {isUploading && (
                        <div className="flex justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                        </div>
                    )}

                    <div className="space-y-2">
                        {attachments.map(attachment => (
                            <div
                                key={attachment.id}
                                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                            >
                                <div
                                    className="flex items-center gap-3 cursor-pointer"
                                    onClick={() => handleDownload(attachment)}
                                >
                                    <FileText size={20} className="text-gray-400" />
                                    <div>
                                        <div className="font-medium text-sm">
                                            {attachment.file_name}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                            {Math.round(attachment.file_size / 1024)} KB
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveAttachment(attachment.id)}
                                    className="text-red-600 hover:text-red-700"
                                >
                                    <Trash2 size={16} />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-3">
                <button
                    type="button"
                    //onClick={() => navigate(-1)}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                    {isEditing ? 'Update Lesson' : 'Create Lesson'}
                </button>
            </div>
        </form>
    );
};

export default LessonForm