import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Children from './pages/Children';
import CheckInOut from './pages/CheckInOut';
import Reporting from './pages/Reporting';
import Unauthorized from './pages/Unauthorized';
import UserManagement from './pages/UserManagement';
import MyChildren from './pages/MyChildren';
import RoleBasedRoute from './components/RoleBasedRoute';
import Header from './components/Header';
import ForgotCredentials from './components/ForgotCredentials';
import ParentCheckInOut from './components/ParentCheckInOut';
import LeaderCheckInOut from './components/LeaderCheckInOut';
import ProfilePage from './pages/ProfilePage';
import { CheckInProvider } from './contexts/CheckInContext';
import Management from './pages/Management';
import CampusManagement from './pages/CampusManagement';
import PasswordReset from './components/PasswordReset';
import StaffLeaderLessons from './components/Lessons/StaffLeaderLessons';
import EventRegistrationDetail from './components/EventManagement/EventRegistrationDetail';
import EventManagement from './pages/EventManagement';
import CommunicationManagement from '../src/components/Communications/CommunicationDetail'
import CommunicationList from '../src/components/Communications/CommunicationList'
import CommunicationForm from '../src/components/Communications/CommunicationList'
import EventRegistrationList from './components/EventManagement/EventRegistrationList';
import CheckInOutManagement from './components/checkin/CheckInOutManagement';
import ChildDetails from './pages/ChildDetails';
import ProtectedRoute from './components/ProtectedRoute';
import { useNavigate } from 'react-router-dom';
import { getStoredUser } from './services/auth';
import { AuthProvider } from './contexts/AuthContext';



function App() {


  return (
    <AuthProvider>
      <div className="min-h-screen bg-gray-100">
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-credentials" element={<ForgotCredentials />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route
            path="/dashboard"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER', 'STAFF', 'PARENT']}>
                <Dashboard />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/children"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER', 'STAFF']}>
                <Children />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/children/:childId"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER', 'STAFF']}>
                <ChildDetails />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/checkin"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER', 'STAFF']}>
                <CheckInOutManagement />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/reports"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <Reporting />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER']}>
                <UserManagement />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/my-children"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'PARENT']}>
                <MyChildren />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/events/manage"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <EventManagement />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/event-registration/:eventId"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'PARENT', 'LEADER', 'STAFF']}>
                <EventRegistrationDetail />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/management"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <Management />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/campus-management"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER']}>
                <CampusManagement />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/leader-checkin"
            element={
              <CheckInProvider>
                <RoleBasedRoute allowedRoles={['LEADER', 'STAFF', 'SUPERUSER']}>
                  <LeaderCheckInOut />
                </RoleBasedRoute>
              </CheckInProvider>
            }
          />
          <Route
            path="/lessons"
            element={
              <CheckInProvider>
                <RoleBasedRoute allowedRoles={['LEADER', 'STAFF', 'SUPERUSER']}>
                  <StaffLeaderLessons />
                </RoleBasedRoute>
              </CheckInProvider>
            }
          />
          <Route
            path="/communications"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER', 'PARENT']}>
                <CommunicationList />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/communications/manage"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <CommunicationManagement />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/communications/create"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <CommunicationForm />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/communications/edit/:id"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'LEADER']}>
                <CommunicationForm />
              </RoleBasedRoute>
            }
          />

          {/* Event Registration Routes */}
          <Route
            path="/event-registration"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'PARENT']}>
                <EventRegistrationList />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/event-registration/:eventId"
            element={
              <RoleBasedRoute allowedRoles={['SUPERUSER', 'PARENT']}>
                <EventRegistrationDetail />
              </RoleBasedRoute>
            }
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/parent-checkin" element={<ParentCheckInOut />} />
        </Routes>

      </div>
    </AuthProvider>
  );
}

export default App;