import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../services/api';

const ForgotCredentials = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState('username');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const endpoint = option === 'username' ? 'forgot-username/' : 'forgot-password/';
      await api.post(endpoint, { email });
      setIsSuccess(true);
    } catch (error) {
      console.error('Forgot credentials error:', error);
      toast.error(
        error.response?.data?.message ||
        'An error occurred. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 text-center">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
              <svg
                className="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>

            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {option === 'username' ? 'Username Recovery Sent' : 'Password Reset Initiated'}
            </h2>

            <p className="text-gray-600 mb-6">
              {option === 'username' ? (
                <>
                  We've sent an email to <span className="font-medium">{email}</span> with
                  your username. Please check your inbox and spam folder.
                </>
              ) : (
                <>
                  We've sent password reset instructions to <span className="font-medium">{email}</span>.
                  Please check your email to complete the password reset process.
                </>
              )}
            </p>

            <div className="space-y-4">
              <p className="text-sm text-gray-500">
                Haven't received the email?
              </p>
              <ul className="text-sm text-gray-600 list-disc list-inside space-y-2">
                <li>Check your spam or junk folder</li>
                <li>Allow a few minutes for the email to arrive</li>
                <li>Make sure you entered the correct email address</li>
              </ul>
            </div>

            <div className="mt-8 space-y-4">
              <button
                onClick={() => setIsSuccess(false)}
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Try Again
              </button>

              <button
                onClick={() => navigate('/login')}
                className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Return to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Account Recovery
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            We'll help you recover your account information
          </p>
        </div>

        <div className="flex justify-center space-x-4">
          <button
            type="button"
            className={`px-4 py-2 rounded-md ${option === 'username'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700'
              }`}
            onClick={() => setOption('username')}
          >
            Forgot Username
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-md ${option === 'password'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700'
              }`}
            onClick={() => setOption('password')}
          >
            Forgot Password
          </button>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 mb-2">
              Enter your email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>

        <div className="text-center">
          <button
            type="button"
            onClick={() => navigate('/login')}
            className="text-sm text-blue-600 hover:text-blue-500"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotCredentials;