import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import api from '../services/api';
import Header from '../components/Header';
import AuthorizedPersons from '../components/AuthorizedPersons';
import { getCurrentUser } from '../services/auth';

const ChildDetails = () => {
    const { childId } = useParams();
    const navigate = useNavigate();
    const [child, setChild] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        fetchUserRole();
        fetchChildDetails();
    }, [childId]);

    const fetchUserRole = async () => {
        try {
            const userData = await getCurrentUser();
            setUserRole({
                isSuperuser: userData.role === 'SUPERUSER',
                isLeader: userData.role === 'LEADER',
                isStaff: userData.role === 'STAFF',
                email: userData.email
            });
        } catch (err) {
            console.error('Error fetching user role:', err);
        }
    };

    const fetchChildDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`children/${childId}/`);
            setChild(response.data);
            setIsLoading(false);
        } catch (err) {
            console.error('Error fetching child details:', err);
            toast.error('Failed to fetch child details');
            setIsLoading(false);
        }
    };

    const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-100">
                <Header />
                <div className="container mx-auto px-4 py-8">
                    <div className="text-center">
                        <div className="spinner"></div>
                        <p className="mt-2 text-gray-600">Loading...</p>
                    </div>
                </div>
            </div>
        );
    }

    if (!child) {
        return (
            <div className="min-h-screen bg-gray-100">
                <Header />
                <div className="container mx-auto px-4 py-8">
                    <p className="text-center text-gray-600">Child not found</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            <div className="container mx-auto px-4 py-8">
                {/* Back Button */}
                <button
                    onClick={handleBack}
                    className="mb-6 flex items-center text-blue-600 hover:text-blue-800"
                >
                    <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    Back to Children List
                </button>

                {/* Child Details Card */}
                <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                    <div className="grid md:grid-cols-2 gap-6">
                        {/* Left Column - Photo */}
                        <div>
                            {child.picture_url ? (
                                <img
                                    src={child.picture_url}
                                    alt={`${child.first_name} ${child.last_name}`}
                                    className="w-full h-64 object-cover rounded-lg"
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center">
                                    <span className="text-gray-500">No photo available</span>
                                </div>
                            )}
                        </div>

                        {/* Right Column - Information */}
                        <div>
                            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                                {child.first_name} {child.last_name}
                            </h1>
                            <div className="space-y-3">
                                <p className="text-gray-600">
                                    <span className="font-semibold">Age:</span> {calculateAge(child.date_of_birth)} years old
                                </p>
                                {(userRole?.isSuperuser || userRole?.isLeader || userRole?.isStaff) && (
                                    <>
                                        <p className="text-gray-600">
                                            <span className="font-semibold">Campus:</span> {child.campus_name || 'N/A'}
                                        </p>
                                        <div className="border-t border-gray-200 pt-3 mt-3">
                                            <h3 className="font-semibold text-gray-800 mb-2">Parent Information</h3>
                                            <p className="text-gray-600">
                                                <span className="font-semibold">Parent:</span> {child.parent_full_name || 'N/A'}
                                            </p>
                                            <p className="text-gray-600">
                                                <span className="font-semibold">Parent Email:</span> {child.parent_email || 'N/A'}
                                            </p>
                                            {child.parent_phones && (
                                                <>
                                                    <p className="text-gray-600">
                                                        <span className="font-semibold">Primary Phone:</span>{' '}
                                                        <a href={`tel:${child.parent_phones.primary_phone}`} className="text-blue-600 hover:text-blue-800">
                                                            {child.parent_phones.primary_phone}
                                                        </a>
                                                    </p>
                                                    {child.parent_phones.secondary_phone && (
                                                        <p className="text-gray-600">
                                                            <span className="font-semibold">Secondary Phone:</span>{' '}
                                                            <a href={`tel:${child.parent_phones.secondary_phone}`} className="text-blue-600 hover:text-blue-800">
                                                                {child.parent_phones.secondary_phone}
                                                            </a>
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                            {child.spouse_name && (
                                                <p className="text-gray-600">
                                                    <span className="font-semibold">Spouse:</span> {child.spouse_name}
                                                </p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Authorized Persons Section */}
                <AuthorizedPersons childId={childId} />
                <ToastContainer />
            </div>
        </div>
    );
};

export default ChildDetails;