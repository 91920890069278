// src/components/AuthorizedPersons.js

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Plus, Edit2, Trash2, X } from 'lucide-react';
import api from '../services/api';

const AuthorizedPersons = ({ childId }) => {
    const [authorizedPersons, setAuthorizedPersons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
    const [editingPerson, setEditingPerson] = useState(null);
    const [newPerson, setNewPerson] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        relationship: '',
        other_relationship_detail: '',
        child: childId,
        is_active: true
    });

    const relationshipOptions = [
        { value: 'GRANDPARENT', label: 'Grandparent' },
        { value: 'AUNT', label: 'Aunt' },
        { value: 'UNCLE', label: 'Uncle' },
        { value: 'SIBLING', label: 'Sibling' },
        { value: 'FAMILY_FRIEND', label: 'Family Friend' },
        { value: 'NANNY', label: 'Nanny/Babysitter' },
        { value: 'OTHER', label: 'Other' }
    ];

    useEffect(() => {
        fetchAuthorizedPersons();
    }, [childId]);

    const fetchAuthorizedPersons = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`authorized-persons/?child_id=${childId}`);
            setAuthorizedPersons(response.data);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewPerson(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('authorized-persons/', newPerson);
            toast.success('Authorized person added successfully!');
            setIsRightPanelOpen(false);
            resetForm();
            fetchAuthorizedPersons();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleUpdate = async () => {
        try {
            const response = await api.put(`authorized-persons/${editingPerson.id}/`);;
            toast.success('Authorized person updated successfully!');
            setIsRightPanelOpen(false);
            resetForm();
            fetchAuthorizedPersons();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to remove this authorized person?')) return;

        try {
            const response = await api.delete(`authorized-persons/${id}/`);
            toast.success('Authorized person removed successfully!');
            fetchAuthorizedPersons();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const resetForm = () => {
        setNewPerson({
            first_name: '',
            last_name: '',
            phone: '',
            relationship: '',
            other_relationship_detail: '',
            child: childId,
            is_active: true
        });
        setEditingPerson(null);
    };

    return (
        <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Authorized Persons</h3>
                <button
                    onClick={() => {
                        resetForm();
                        setIsRightPanelOpen(true);
                    }}
                    className="flex items-center gap-2 bg-blue-600 text-white px-3 py-1 rounded-lg hover:bg-blue-700 transition-colors text-sm"
                >
                    <Plus size={16} />
                    Add Person
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center h-32">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Phone</th>
                                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Relationship</th>
                                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {authorizedPersons.map(person => (
                                    <tr key={person.id} className="hover:bg-gray-50">
                                        <td className="px-4 py-2">{`${person.first_name} ${person.last_name}`}</td>
                                        <td className="px-4 py-2">{person.phone}</td>
                                        <td className="px-4 py-2">
                                            {person.relationship === 'OTHER'
                                                ? person.other_relationship_detail
                                                : person.relationship_display}
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="flex gap-2">
                                                <button
                                                    onClick={() => {
                                                        setEditingPerson(person);
                                                        setNewPerson(person);
                                                        setIsRightPanelOpen(true);
                                                    }}
                                                    className="p-1 text-yellow-600 hover:text-yellow-700"
                                                >
                                                    <Edit2 size={16} />
                                                </button>
                                                <button
                                                    onClick={() => handleDelete(person.id)}
                                                    className="p-1 text-red-600 hover:text-red-700"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {authorizedPersons.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="px-4 py-4 text-center text-gray-500">
                                            No authorized persons added yet.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* Right Panel for Add/Edit Authorized Person */}
            <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-lg transform ${isRightPanelOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
                <div className="h-full flex flex-col p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className="text-xl font-bold text-gray-800">
                            {editingPerson ? 'Edit Authorized Person' : 'Add Authorized Person'}
                        </h3>
                        <button
                            onClick={() => {
                                setIsRightPanelOpen(false);
                                resetForm();
                            }}
                            className="p-2 hover:bg-gray-100 rounded-full"
                        >
                            <X size={20} />
                        </button>
                    </div>

                    <form onSubmit={editingPerson ? handleUpdate : handleSubmit} className="flex-1 flex flex-col gap-4">
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={newPerson.first_name}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                        />
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={newPerson.last_name}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={newPerson.phone}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                        />
                        <select
                            name="relationship"
                            value={newPerson.relationship}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                        >
                            <option value="">Select Relationship</option>
                            {relationshipOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>

                        {newPerson.relationship === 'OTHER' && (
                            <input
                                type="text"
                                name="other_relationship_detail"
                                placeholder="Please specify relationship"
                                value={newPerson.other_relationship_detail}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                required
                            />
                        )}

                        <div className="flex items-center gap-2">
                            <input
                                type="checkbox"
                                id="is_active"
                                name="is_active"
                                checked={newPerson.is_active}
                                onChange={handleInputChange}
                                className="rounded border-gray-300"
                            />
                            <label htmlFor="is_active">Active</label>
                        </div>

                        <button
                            type="submit"
                            className="mt-auto bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            {editingPerson ? 'Update Person' : 'Add Person'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AuthorizedPersons;