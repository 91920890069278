import React, { useState, useEffect } from 'react';
import { X, Upload, File, Trash2, AlertCircle, FileText } from 'lucide-react';
import { toast } from 'react-toastify';
import api from '../../services/api';

const CommunicationForm = ({ isOpen, onClose, communication, onSuccess, currentUser }) => {
    const [formData, setFormData] = useState({
        title: '',
        message: '',
        communication_type: 'ANNOUNCEMENT',
        child: '',
        parent: '',
        send_to_all_children: false,
        attachment: null,
        campus: '',
    });
    const [children, setChildren] = useState([]);
    const [parents, setParents] = useState([]);
    const [campuses, setCampuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [dragActive, setDragActive] = useState(false);



    const handleDragDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = Array.from(e.dataTransfer.files);
        await handleFileUpload({ target: { files } });
    };


    useEffect(() => {
        fetchCampuses();
        if (communication) {
            setFormData({
                title: communication.title || '',
                message: communication.message || '',
                communication_type: communication.communication_type || 'ANNOUNCEMENT',
                child: communication.child || '',
                parent: communication.parent || '',
                send_to_all_children: communication.send_to_all_children || false,
                campus: communication.campus || '',
            });
            setAttachments(communication.attachments || []);
        }
    }, [communication]);

    useEffect(() => {
        if (formData.communication_type === 'CHILD_UPDATE' && formData.parent) {
            fetchChildrenForParent(formData.parent);
        }
    }, [formData.communication_type, formData.parent]);

    const fetchCampuses = async () => {
        try {
            const response = await api.get('campuses/');
            setCampuses(response.data);
        } catch (error) {
            console.error('Error fetching campuses:', error);
        }
    };

    const fetchParents = async () => {
        try {
            const response = await api.get('users/parents/');
            setParents(response.data);
        } catch (error) {
            console.error('Error fetching parents:', error);
        }
    };

    const fetchChildrenForParent = async (parentId) => {
        try {
            const response = await api.get(`parents/${parentId}/children/`);
            setChildren(response.data);
        } catch (error) {
            console.error('Error fetching children:', error);
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = e.dataTransfer.files;
        //await handleFiles(files);
    };

    const handleFileInput = async (e) => {
        const files = e.target.files;
        //await handleFiles(files);
    };

    const ALLOWED_FILE_TYPES = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain'
    ];

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const isFileTypeAllowed = (file) => {
        const fileType = file.type;
        return ALLOWED_FILE_TYPES.includes(fileType);
    };

    const validateFile = (file) => {
        if (!isFileTypeAllowed(file)) {
            return 'File type not allowed';
        }
        if (file.size > MAX_FILE_SIZE) {
            return `File size exceeds ${formatBytes(MAX_FILE_SIZE)}`;
        }
        return null;
    };

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        setUploading(true);

        for (const file of files) {
            try {
                if (!communication?.id) {
                    toast.error('Please save the communication first before adding attachments');
                    continue;
                }

                // Step 1: Get presigned URL
                const presignedUrlResponse = await api.post('file-upload/', {
                    fileName: file.name,
                    fileType: file.type,
                    directory: 'communications'
                });

                if (!presignedUrlResponse.data?.url) {
                    throw new Error('Failed to get upload URL');
                }

                console.log('Presigned URL:', presignedUrlResponse.data.url);
                console.log('File details:', {
                    name: file.name,
                    type: file.type,
                    size: file.size
                });

                // Step 2: Upload to S3
                const uploadResponse = await fetch(presignedUrlResponse.data.url, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type
                    }
                });

                if (!uploadResponse.ok) {
                    console.error('S3 Upload Error:', await uploadResponse.text());
                    throw new Error(`Failed to upload to S3: ${uploadResponse.statusText}`);
                }

                // Step 3: Notify backend about successful upload
                const completeResponse = await api.post('file-upload/complete/', {
                    fileKey: presignedUrlResponse.data.fileKey,
                    modelType: 'communication',
                    modelId: communication.id,
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size
                });

                if (completeResponse.data?.status === 'success') {
                    const newAttachment = {
                        id: completeResponse.data.attachmentId || presignedUrlResponse.data.fileKey,
                        file_name: file.name,
                        file_type: file.type,
                        file_size: file.size,
                        file_key: presignedUrlResponse.data.fileKey
                    };

                    setAttachments(prev => [...prev, newAttachment]);
                    toast.success(`${file.name} uploaded successfully`);
                }
            } catch (error) {
                console.error('Upload error:', error);
                toast.error(`Failed to upload ${file.name}: ${error.message}`);
            }
        }

        setUploading(false);
    };

    const handleRemoveAttachment = async (attachmentId) => {
        try {
            // Use the correct endpoint from your API
            await api.delete(`communications/${communication?.id}/remove-attachment/${attachmentId}/`);
            setAttachments(prev => prev.filter(att => att.id !== attachmentId));
            toast.success('Attachment removed successfully');
        } catch (error) {
            console.error('Remove attachment error:', error);
            toast.error('Failed to remove attachment: ' + (error.response?.data?.message || 'Unknown error'));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Create FormData instance for the main form submission
            const formDataToSend = new FormData();

            // Append all the form fields
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null && formData[key] !== undefined) {
                    formDataToSend.append(key, formData[key]);
                }
            });

            // Append attachment IDs if any
            attachments.forEach(att => {
                formDataToSend.append('attachment_ids[]', att.id);
            });

            if (communication) {
                await api.put(`communications/${communication.id}/`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                toast.success('Communication updated successfully');
            } else {
                await api.post('communications/', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                toast.success('Communication created successfully');
            }

            onSuccess();
            onClose();
        } catch (error) {
            console.error('Submit error:', error);
            toast.error(error.response?.data?.message || 'Error processing your request');
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (attachment) => {
        try {
            const response = await api.get(`communications/${communication.id}/attachments/${attachment.id}/download/`);
            if (response.data.url) {
                window.open(response.data.url, '_blank');
            }
        } catch (error) {
            console.error('Download error:', error);
            toast.error('Failed to download file');
        }
    };


    return (
        <div className="fixed inset-y-0 right-0 w-full md:w-[600px] bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 overflow-y-auto">
            <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                    <h3 className="text-xl font-bold">
                        {communication ? 'Edit Communication' : 'New Communication'}
                    </h3>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <X size={20} />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                            required
                            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Type
                        </label>
                        <select
                            name="communication_type"
                            value={formData.communication_type}
                            onChange={(e) => setFormData(prev => ({ ...prev, communication_type: e.target.value }))}
                            required
                            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="ANNOUNCEMENT">Announcement (All Users)</option>
                            <option value="CHILD_UPDATE">Child Update (Specific Parent)</option>
                        </select>

                        {formData.communication_type === 'CHILD_UPDATE' && (
                            <>
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Select Parent
                                    </label>
                                    <select
                                        value={formData.parent}
                                        onChange={(e) => setFormData(prev => ({ ...prev, parent: e.target.value }))}
                                        required
                                        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    >
                                        <option value="">Select Parent</option>
                                        {parents.map(parent => (
                                            <option key={parent.id} value={parent.id}>
                                                {parent.full_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {formData.parent && (
                                    <div className="mt-4">
                                        <div className="flex items-center mb-2">
                                            <input
                                                type="checkbox"
                                                id="sendToAll"
                                                checked={formData.send_to_all_children}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    send_to_all_children: e.target.checked,
                                                    child: e.target.checked ? '' : prev.child
                                                }))}
                                                className="mr-2"
                                            />
                                            <label htmlFor="sendToAll" className="text-sm text-gray-700">
                                                Send to all children of this parent
                                            </label>
                                        </div>

                                        {!formData.send_to_all_children && (
                                            <select
                                                value={formData.child}
                                                onChange={(e) => setFormData(prev => ({ ...prev, child: e.target.value }))}
                                                required
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            >
                                                <option value="">Select Child</option>
                                                {children.map(child => (
                                                    <option key={child.id} value={child.id}>
                                                        {child.full_name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Message
                        </label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                            required
                            rows={6}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    {/* Attachment section */}
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h3 className="text-lg font-medium mb-4">Attachments</h3>

                        <div className="space-y-4">
                            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDragDrop}>
                                <input
                                    type="file"
                                    onChange={handleFileUpload}
                                    multiple
                                    className="hidden"
                                    id="file-upload"
                                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                                />
                                <label
                                    htmlFor="file-upload"
                                    className="flex flex-col items-center justify-center cursor-pointer"
                                >
                                    <Upload size={24} className="text-gray-400 mb-2" />
                                    <span className="text-sm text-gray-600">
                                        Click to upload files or drag and drop
                                    </span>
                                    <span className="text-xs text-gray-500 mt-1">
                                        PDF, DOC, DOCX, PPT, PPTX up to 10MB
                                    </span>
                                </label>
                            </div>

                            {uploading && (
                                <div className="flex justify-center">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                </div>
                            )}

                            <div className="space-y-2">
                                {attachments.map(attachment => (
                                    <div
                                        key={attachment.id}
                                        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                                    >
                                        <div
                                            className="flex items-center gap-3 cursor-pointer"
                                            onClick={() => handleDownload(attachment)}
                                        >
                                            <FileText size={20} className="text-gray-400" />
                                            <div>
                                                <div className="font-medium text-sm">
                                                    {attachment.file_name}
                                                </div>
                                                <div className="text-xs text-gray-500">
                                                    {Math.round(attachment.file_size / 1024)} KB
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveAttachment(attachment.id)}
                                            className="text-red-600 hover:text-red-700"
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <button
                        type="submit"
                        disabled={loading || uploading}
                        className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-400"
                    >
                        {loading ? 'Processing...' : (communication ? 'Update Communication' : 'Send Communication')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CommunicationForm;