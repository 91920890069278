// components/checkin/CheckInOutManagement.js
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import { MapPin, Calendar, Clock, Search, Check, X } from 'lucide-react';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

const CheckInOutManagement = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [registrations, setRegistrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const { user, refreshUser } = useAuth();

    useEffect(() => {
        setCurrentUser(user);
        fetchTodayEvents();
    }, []);

    const fetchTodayEvents = async () => {
        try {
            const today = format(new Date(), 'yyyy-MM-dd');
            let url = `events/?date=${today}&status=PUBLISHED`;

            // If user is a leader, filter by campus
            if (currentUser?.role === 'LEADER' && !currentUser?.is_superuser) {
                url += `&campus=${currentUser.campus}`;
            }

            const response = await api.get(url);
            setEvents(response.data || []);
        } catch (error) {
            console.error('Error fetching events:', error);
            toast.error('Failed to fetch events');
        } finally {
            setLoading(false);
        }
    };

    const fetchRegistrations = async (eventId) => {
        try {
            const response = await api.get(`event-registrations/?event=${eventId}`);
            setRegistrations(response.data || []);
        } catch (error) {
            console.error('Error fetching registrations:', error);
            toast.error('Failed to fetch registrations');
        }
    };

    const handleEventSelect = async (event) => {
        setSelectedEvent(event);
        await fetchRegistrations(event.id);
    };

    const handleCheckIn = async (registrationId) => {
        try {
            await api.post(`event-registrations/${registrationId}/check_in/`);
            toast.success('Check-in successful');
            await fetchRegistrations(selectedEvent.id);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to check in');
        }
    };

    const handleCheckOut = async (registrationId) => {
        try {
            await api.post(`event-registrations/${registrationId}/check_out/`);
            toast.success('Check-out successful');
            await fetchRegistrations(selectedEvent.id);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to check out');
        }
    };

    // Safe filtering with null checks
    const filteredRegistrations = registrations.filter(reg => {
        const childName = reg?.child?.name || reg?.child_name || '';
        return childName.toLowerCase().includes((searchTerm || '').toLowerCase());
    });

    if (loading) return <div>Loading...</div>;

    return (
        <div className="container mx-auto px-4 py-8">
            <ToastContainer />

            {/* Event Selection */}
            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Today's Events</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {events.map(event => (
                        <button
                            key={event.id}
                            onClick={() => handleEventSelect(event)}
                            className={`p-4 rounded-lg border ${selectedEvent?.id === event.id
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-200 hover:border-blue-300'
                                }`}
                        >
                            <h3 className="font-bold text-lg mb-2">{event.name}</h3>
                            <div className="flex items-center gap-2 text-sm text-gray-600">
                                <Clock size={16} />
                                <span>
                                    {event.start_time ? format(new Date(`2000-01-01T${event.start_time}`), 'h:mm a') : ''} -
                                    {event.end_time ? format(new Date(`2000-01-01T${event.end_time}`), 'h:mm a') : ''}
                                </span>
                            </div>
                            <div className="flex items-center gap-2 text-sm text-gray-600">
                                <MapPin size={16} />
                                <span>{event.campus_name || 'No location specified'}</span>
                            </div>
                        </button>
                    ))}

                    {events.length === 0 && (
                        <div className="col-span-full text-center py-8 text-gray-500">
                            No events scheduled for today
                        </div>
                    )}
                </div>
            </div>

            {/* Registration List */}
            {selectedEvent && (
                <div className="bg-white rounded-lg shadow p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-bold">Registrations for {selectedEvent.name}</h2>
                        <div className="flex items-center gap-2">
                            <Search size={20} className="text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search by child name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="border-b-2 border-gray-200">
                                    <th className="px-4 py-2 text-left">Child Name</th>
                                    <th className="px-4 py-2 text-left">Parent</th>
                                    <th className="px-4 py-2 text-left">Status</th>
                                    <th className="px-4 py-2 text-left">Check In</th>
                                    <th className="px-4 py-2 text-left">Check Out</th>
                                    <th className="px-4 py-2 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRegistrations.map(registration => (
                                    <tr key={registration.id} className="border-b border-gray-200">
                                        <td className="px-4 py-2">
                                            {registration.child?.name || registration.child_name || 'N/A'}
                                        </td>
                                        <td className="px-4 py-2">
                                            {registration.parent?.name || registration.parent_name || 'N/A'}
                                        </td>
                                        <td className="px-4 py-2">
                                            <span className={`px-2 py-1 rounded-full text-xs ${registration.checked_out ? 'bg-green-100 text-green-800' :
                                                registration.checked_in ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-gray-100 text-gray-800'
                                                }`}>
                                                {registration.checked_out ? 'Checked Out' :
                                                    registration.checked_in ? 'Checked In' :
                                                        'Not Checked In'}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2">
                                            {registration.check_in_time ?
                                                format(new Date(registration.check_in_time), 'h:mm a') :
                                                '-'}
                                        </td>
                                        <td className="px-4 py-2">
                                            {registration.check_out_time ?
                                                format(new Date(registration.check_out_time), 'h:mm a') :
                                                '-'}
                                        </td>
                                        <td className="px-4 py-2">
                                            {!registration.checked_in ? (
                                                <button
                                                    onClick={() => handleCheckIn(registration.id)}
                                                    className="p-2 text-blue-600 hover:text-blue-800"
                                                >
                                                    <Check size={20} />
                                                </button>
                                            ) : !registration.checked_out ? (
                                                <button
                                                    onClick={() => handleCheckOut(registration.id)}
                                                    className="p-2 text-red-600 hover:text-red-800"
                                                >
                                                    <X size={20} />
                                                </button>
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {filteredRegistrations.length === 0 && (
                            <div className="text-center py-8 text-gray-500">
                                No registrations found
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckInOutManagement;