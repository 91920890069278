// src/components/ui/dialog/index.jsx
import React from 'react';

const Dialog = ({ open, onOpenChange, children }) => {
    if (!open) return null;

    return (
        <div
            className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center"
            onClick={() => onOpenChange?.(false)}
        >
            <div
                className="bg-white rounded-lg max-w-lg w-full mx-4"
                onClick={e => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
};

const DialogContent = ({ children, className = '' }) => {
    return (
        <div className={`p-6 ${className}`}>
            {children}
        </div>
    );
};

const DialogHeader = ({ children, className = '' }) => {
    return (
        <div className={`space-y-1.5 text-center sm:text-left ${className}`}>
            {children}
        </div>
    );
};

const DialogTitle = ({ children, className = '' }) => {
    return (
        <h3 className={`text-lg font-semibold leading-none tracking-tight ${className}`}>
            {children}
        </h3>
    );
};

export { Dialog, DialogContent, DialogHeader, DialogTitle };