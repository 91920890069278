import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api/';


const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});


const axiosInstance = axios.create({
  headers: {
    Authorization: `Token ${localStorage.getItem('token')}`, // Dynamically fetch the token
  },
});

/**
 * Gets the current authenticated user from the backend
 * @returns {Promise<Object>} The current user data
 */
export const getCurrentUser = async () => {
  try {
    // First check if we have a token
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    // Make an API call to verify and get current user
    const response = await api.get('current-user/');
    if (!response.data) {
      throw new Error('No user data received');
    }

    // Update local storage with fresh data
    localStorage.setItem('user', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error('Error getting current user:', error);
    // Clear potentially invalid auth data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    throw error;
  }
};

/**
 * Creates an auth state manager with periodic validation
 * @returns {Object} Auth state management methods
 */
export const createAuthManager = () => {
  let currentUser = null;
  let validateInterval = null;

  const startValidationInterval = () => {
    // Validate session every 5 minutes
    validateInterval = setInterval(async () => {
      try {
        await getCurrentUser();
      } catch (error) {
        stopValidationInterval();
        window.dispatchEvent(new Event('auth-invalid'));
      }
    }, 5 * 60 * 1000); // 5 minutes
  };

  const stopValidationInterval = () => {
    if (validateInterval) {
      clearInterval(validateInterval);
      validateInterval = null;
    }
  };

  return {
    /**
     * Initialize the auth state
     */
    init: async () => {
      try {
        currentUser = await getCurrentUser();
        startValidationInterval();
        return currentUser;
      } catch (error) {
        currentUser = null;
        throw error;
      }
    },

    /**
     * Get the current user with validation
     */
    getUser: async () => {
      try {
        currentUser = await getCurrentUser();
        return currentUser;
      } catch (error) {
        currentUser = null;
        throw error;
      }
    },

    /**
     * Clean up auth state
     */
    cleanup: () => {
      stopValidationInterval();
      currentUser = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  };
};



const token = localStorage.getItem('token');

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const authExcludedRoutes = [
      'forgot-password',
      'forgot-username',
      'forgot-credentials',  // Add this
      'signup',
      'login'
    ];

    const isAuthExcluded = authExcludedRoutes.some(route => config.url.includes(route));

    if (token && !isAuthExcluded) {
      config.headers['Authorization'] = `Token ${token}`;  // Add token to Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const forgotCredentials = (email) => api.post('forgot-credentials/', { email });

export const getCheckInCode = async (parentId) => {
  const token = localStorage.getItem('token'); // Use token for authorization
  return await axios.get(`${API_URL}parents/${parentId}/check_in_code/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const getUsers = async () => {
  try {
    const response = await api.get('all/');
    return response;
  } catch (error) {
    console.error('Error in getUsers:', error);
    throw error;
  }
};
//export const getCurrentUser = () => api.get('current_user');
//export const createUser = (userData) => api.post('users/', userData);

export const createUser = async (userData) => {
  try {
    // Create user with complete data including email template preference
    const response = await api.post('users/', {
      ...userData,
      send_welcome_email: true  // Add this flag to trigger welcome email
    });
    return response;
  } catch (error) {
    console.error('API Error creating user:', error.response || error);
    throw error;
  }
};



export const login = (username, password) => api.post('login/', { username, password });
export const logout = () => api.post('logout/');
export const signup = (userData) => api.post('signup/', userData);
export const forgotPassword = (email) => api.post('forgot-password/', { email });
export const getMyChildren = () => api.get('children/');
export const updateUser = (userId, userData) => api.put(`users/${userId}/`, userData);
export const deleteUser = (userId) => api.delete(`users/${userId}/`);
export const getChildren = () => api.get('children-list/');
export const getParents = () => api.get('parents/');
//export const addChild = (childData) => api.post('children/add_child/', childData);


export const updateChild = async (childId, updatedData) => {
  const token = localStorage.getItem('token');
  return await api.put(`${API_URL}children/${childId}/`, updatedData, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

/*
export const addChild = async (childData) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('first_name', childData.first_name);
  formData.append('last_name', childData.last_name);
  formData.append('date_of_birth', childData.date_of_birth);
  if (childData.picture) {
    formData.append('picture', childData.picture);
  }

  const response = await axios.post(`${API_URL}parents/add_child/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Token ${token}`,
    },
  });
  return response;
};
*/

export const addChild = (formData) => {
  return api.post('/parents/add_child/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteChild = async (childId) => {
  const token = localStorage.getItem('token');
  return await axios.delete(`${API_URL}children/${childId}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
export const getCheckins = () => api.get('checkins/');
export const generateCheckInCode = async (parentId) => {
  // Get the token from local storage
  const token = localStorage.getItem('token'); // Ensure the token is stored after login

  return axios.post(`${API_URL}parents/${parentId}/generate_check_in_code/`, null, {
    headers: {
      Authorization: `Token ${token}`  // Add token to the Authorization header
    }
  });
};

// Fetch children by 6-digit code
export const getChildrenByCode = (code) => {
  return axios.post(`${API_URL}parents/children_by_code/`, { code }, {
    headers: {
      Authorization: `Token ${token}`  // Include token in headers
    }
  });
};


export const getCheckInHistory = (childId, page = 1, startDate, endDate) => {
  let url = `children/${childId}/checkins/?page=${page}`;
  if (startDate) url += `&start_date=${startDate}`;
  if (endDate) url += `&end_date=${endDate}`;
  return api.get(url);
};

// Check in a child
export const checkInChild = async (childId) => {
  const token = localStorage.getItem('token');
  return await axios.post(`${API_URL}checkin/`, { child_id: childId }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

// Check out a child
export const checkOutChild = async (childId) => {
  const token = localStorage.getItem('token');
  return await axios.post(`${API_URL}checkout/`, { child_id: childId }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};


// Search for a parent
export const searchParent = async (searchTerm) => {
  const token = localStorage.getItem('token');
  return await axios.get(`${API_URL}parents/?search=${searchTerm}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};



export default api;